import { Box, Button, Checkbox, FormControlLabel, Link, Modal, Stack, TextField, Typography, textFieldClasses } from "@mui/material"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #091e44',
    boxShadow: 24,
    textAlign: 'center',
    p: 10
};


const SigninModal = (props) => {

    const handleSignupOpen = () => {
        props.handleClose()
        props.handleSignupModal()
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                <Typography variant="h2">
                    Welcome to my Daycare
                </Typography>

                <Typography variant="body2" color='text.dark'>
                    Login to your account
                </Typography>

                <Typography variant="h5" color='text.dark' sx={{ textAlign: 'left' }}>
                    Email
                </Typography>
                <TextField
                    name="email"
                    label="Enter your email"
                    autoComplete="email"
                    size="small"
                    fullWidth
                // value={formik.values.firstname}
                // onChange={formik.handleChange}
                // error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                // helperText={formik.touched.firstname && formik.errors.firstname}
                />

                <Typography variant="h5" color='text.dark' sx={{ textAlign: 'left', mt: 2 }}>
                    Password
                </Typography>

                <TextField
                    name="email"
                    label="Enter your password"
                    autoComplete="email"
                    size="small"
                    fullWidth
                // value={formik.values.firstname}
                // onChange={formik.handleChange}
                // error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                // helperText={formik.touched.firstname && formik.errors.firstname}
                />

                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <FormControlLabel
                        label="Remember me"
                        control={
                            <Checkbox color="secondary"
                            />
                        }
                    />
                    <Link>
                        Forgot password?
                    </Link>
                </Stack>

                <Button
                    variant="contained"
                    size="large"
                    type="submit"
                >
                    Login
                </Button>

                <Typography variant="body2" color='text.dark' sx={{ py: 2 }}>
                    or Signup with
                </Typography>

                <Stack direction='row' spacing={3} justifyContent='center'>
                    {<img src="/images/icons/fb_blue.png" />}
                    {<img src="/images/icons/apple_blue.png" />}
                    {<img src="/images/icons/email_blue.png" />}
                </Stack>

                <Typography variant="body2" color='text.dark' sx={{ py: 2 }}>
                    Don't have an account? <Link onClick={handleSignupOpen}>Signup Now</Link>
                </Typography>

            </Box>
        </Modal>
    )
}

export default SigninModal