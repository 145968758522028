import {
    Container, Box, Grid, Typography, Button
} from '@mui/material';
import { Link } from "react-router-dom";


import Search from '../../Search/index.js';

import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer.js';

function Articles() {

    return (
        <>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>


            <Box sx={{ p: 4 }}>


                <Typography color='text.dark' sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'center' }}>
                    Finding a DayCare | Talking about the Menu | Child Development | The Education Corner | Teachers Share | Diversity, Equity and Inclusion | Ministry of Education Information | Family Time | Naps and Bedtime | Growth Mindset
                </Typography>

                <Box>
                    <Typography variant="h4" color="text.dark" sx={{ pt: 3, pb: 2 }}>
                        Articles Topics
                    </Typography>

                    <Grid container spacing={5}>
                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#FFB200' }}>
                                <Box sx={{ backgroundColor: '#FFB200' }}>
                                    <img alt="" src={`/images/article/article1.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#FFB200', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#FF5C49' }}>
                                <Box sx={{ backgroundColor: '#FF5C49' }}>
                                    <img alt="" src={`/images/article/article2.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#FF5C49', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{
                                border: 2, borderRadius: 2, borderColor: '#6BC53C', height: '100%',
                                alignItems: 'center', display: 'flex', backgroundColor: '#6BC53C'

                            }}>
                                {<img alt="" src="/images/business_medium_ad.png" width='100%' />}
                            </Box>

                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#FF5C49' }}>
                                <Box sx={{ backgroundColor: '#FF5C49' }}>
                                    <img alt="" src={`/images/article/article3.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#FF5C49', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#6BC53C' }}>
                                <Box sx={{ backgroundColor: '#6BC53C' }}>
                                    <img alt="" src={`/images/article/article4.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#6BC53C', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#FFB200' }}>
                                <Box sx={{ backgroundColor: '#FFB200' }}>
                                    <img alt="" src={`/images/article/article5.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#FFB200', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#FFB200' }}>
                                <Box sx={{ backgroundColor: '#FFB200' }}>
                                    <img alt="" src={`/images/article/article6.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#FFB200', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#FF5C49' }}>
                                <Box sx={{ backgroundColor: '#FF5C49' }}>
                                    <img alt="" src={`/images/article/article7.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#FF5C49', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#6BC53C' }}>
                                <Box sx={{ backgroundColor: '#6BC53C' }}>
                                    <img alt="" src={`/images/article/article8.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#6BC53C', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#FF5C49' }}>
                                <Box sx={{ backgroundColor: '#FF5C49' }}>
                                    <img alt="" src={`/images/article/article9.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#FF5C49', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{ border: 2, borderRadius: 2, borderColor: '#6BC53C' }}>
                                <Box sx={{ backgroundColor: '#6BC53C' }}>
                                    <img alt="" src={`/images/article/article10.png`} width='100%' />
                                </Box>
                                <Typography variant='h3' color='text.white' sx={{ backgroundColor: '#6BC53C', textAlign: 'center' }}>
                                    Article Title
                                </Typography>

                                <Typography color="secondary.main" sx={{ p: 1, fontSize: '1.5rem', textAlign: 'justify' }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam <Link>read more...</Link>
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={4}>
                            <Box sx={{
                                border: 2, borderRadius: 2, borderColor: '#FFB200', height: '100%',
                                alignItems: 'center', display: 'flex', backgroundColor: '#FFB200'

                            }}>
                                {<img alt="" src="/images/business_medium_ad.png" width='100%' />}
                            </Box>

                        </Grid>

                    </Grid>

                </Box>
                <Button
                    variant="contained"
                    size="large"
                    sx={{ mt: 2 }}
                >
                    See More Results
                </Button>

            </Box >


            <Box sx={{ p: 3 }}>
                {<img alt="" src="/images/team/team_page_ad.png" width='100%' />}
            </Box>

            <Footer />

        </>
    );
}

export default Articles;