import { useEffect } from 'react';
import Aos from 'aos';

import 'aos/dist/aos.css';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

import { Outlet } from 'react-router-dom';

function Home() {

    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
            offset: 120,
        })
    }, [])

    return (
        <>
            <Outlet />
            <Footer />
        </>
    );
}

export default Home;