import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';

import MenuIcon from '@mui/icons-material/Menu';
import SigninModal from '../Auth/SigninModal';
import SignupModal from '../Auth/SignupModal';
import WaitinglistModal from '../WaitingList/waitinglistModal';





const menuitems = [
    { name: 'Write a Review', url: '/' },
    { name: 'Recreational', url: '/' },
    { name: 'For Parents', url: '/' },
    { name: 'Community Hub', url: '/' },
    { name: 'Login/Signup', onclick: true },
    { name: 'For Care Providers', url: '' },
    { name: 'Join a Waiting List', url: '' }
];


const Navbar = (props) => {

    const [openSigninModal, setOpenSigninModal] = useState(false);

    const handleSigninModal = () => {
        setOpenSigninModal(true)
    }

    const [openSignupModal, setOpenSignupModal] = useState(false);

    const handleSignupModal = () => {
        setOpenSignupModal(true)
    }

    const [openWaitinglistModal, setOpenWaitinglistModal] = useState(false);

    const handleWaitinglistModal = () => {
        setOpenWaitinglistModal(true)
    }


    const location = useLocation();

    const [drawer, setDrawer] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) { return; }
        setDrawer(open);
    };

    return (


        <AppBar position='static' style={{ background: 'transparent' }} elevation={0}>

            <Toolbar sx={{ alignItems: 'flex-end' }} >
                <Box flexGrow={1} sx={{ display: { xs: 'flex', md: 'none' }, px: 5 }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <SwipeableDrawer
                        open={drawer}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        <Box
                            sx={{ width: 250 }}
                            role="presentation"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            <Toolbar
                                sx={{
                                    my: 3,
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                {<img alt="Daycare" src="../images/logo.png" />}
                            </Toolbar>
                            <List>
                                {menuitems.map((menuitem) => (
                                    <ListItem key={menuitem.name} disablePadding>
                                        {menuitem.onclick ?
                                            <ListItemButton
                                                selected={menuitem.url === location.pathname.toLowerCase()}
                                                sx={{
                                                    '&:hover': {
                                                        bgcolor: 'primary.lighter'
                                                    },
                                                    '&.Mui-selected': {
                                                        bgcolor: 'primary.lighter',
                                                        borderRight: "2px solid",
                                                        borderColor: "primary.main",
                                                        color: "primary.main",
                                                        '&:hover': {
                                                            color: "primary.main",
                                                            bgcolor: 'primary.lighter'
                                                        }
                                                    }
                                                }}>
                                                <ListItemIcon>
                                                    {menuitem.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={menuitem.name} />
                                            </ListItemButton>

                                            :
                                            <ListItemButton
                                                component={Link}

                                                to={menuitem.url}
                                                selected={menuitem.url === location.pathname.toLowerCase()}
                                                sx={{
                                                    '&:hover': {
                                                        bgcolor: 'primary.lighter'
                                                    },
                                                    '&.Mui-selected': {
                                                        bgcolor: 'primary.lighter',
                                                        borderRight: "2px solid",
                                                        borderColor: "primary.main",
                                                        color: "primary.main",
                                                        '&:hover': {
                                                            color: "primary.main",
                                                            bgcolor: 'primary.lighter'
                                                        }
                                                    }
                                                }}>
                                                <ListItemIcon>
                                                    {menuitem.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={menuitem.name} />
                                            </ListItemButton>
                                        }
                                    </ListItem>
                                ))}
                            </List>

                        </Box>
                    </SwipeableDrawer>
                </Box>

                <Box flexGrow={1} sx={{ mt: 3, mr: 2 }} >
                    <Box component={Link} to="/" sx={{ textDecoration: 'none' }}>
                        {<img alt="Daycare" src="/images/logo.png" />}
                    </Box>
                </Box>

                <Box sx={{ mb: 3, display: { xs: 'none', md: 'flex' } }}>

                    <Button
                        component={Link}
                        to="/Comparison"
                        sx={{ color: '#fff' }}
                    >
                        Write a Review
                    </Button>

                    <Button
                        component={Link}
                        to="/SearchResult" sx={{ color: '#fff' }}
                    >
                        Recreational
                    </Button>

                    <Button
                        component={Link}
                        to="/Business" sx={{ color: '#fff' }}
                    >
                        For Parents
                    </Button>

                    <Button
                        component={Link}
                        to="/" sx={{ color: '#fff' }}
                    >
                        Community Hub
                    </Button>

                    <Button
                        sx={{ color: '#fff' }}
                        onClick={handleSigninModal}
                    >
                        Login/Signup
                    </Button>
                    <Button
                        component={Link}
                        to="/" sx={{ color: '#fff' }}
                    >
                        For Care Providers
                    </Button>
                    <Button
                        onClick={handleWaitinglistModal}
                        sx={{ color: '#fff' }}
                    >
                        Join a Waiting List
                    </Button>
                </Box>

            </Toolbar>

            <SigninModal
                open={openSigninModal}
                handleClose={() => setOpenSigninModal(false)}
                handleSignupModal={() => handleSignupModal()}
            />

            <SignupModal
                open={openSignupModal}
                handleSignupClose={() => setOpenSignupModal(false)}
            />

            <WaitinglistModal
                open={openWaitinglistModal}
                handleClose={() => setOpenWaitinglistModal(false)}
            />
        </AppBar>
    )
}

export default Navbar;