import { useFormik } from "formik";
import * as yup from 'yup';


import { Box, Grid, Paper, Container, TextField, Button, Stack, Typography, Rating, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BusinessIcon from '@mui/icons-material/Business';
import { useState } from "react";

const validationSchema = yup.object({
    firstname: yup.string()
        .required('first name required'),
    firstname: yup.string()
        .required('last name required'),
    email: yup.string()
        .email('invalid email')
        .required('email required'),
    phoneNumber: yup.string()
        .required('contact number required'),
    subject: yup.string()
        .required("subject required"),
    message: yup.string()
        .required("message required"),
});

const ContactUs = () => {

    const [value, setValue] = useState(3);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //await api.contactUs(values);
        },
    });


    return (
        <Container maxWidth="lg">

            <Box>

                <Grid container spacing={5}>
                    <Grid item xs sx={{ textAlign: 'center' }}>
                        <Paper elevation={3} sx={{ px: 5, py: 2, borderRadius: '10px' }}>

                            <Typography variant="h1" color="text.yellow">
                                Contact us
                            </Typography>

                            <Typography variant="h6" color="secondary.main" sx={{ mt: 2, px: 10 }}>
                                Your feedback helps parents to choose the best care for their children
                            </Typography>

                            <Box component="form" onSubmit={formik.handleSubmit} sx={{ textAlign: 'left' }} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h3" color="text.secondary">
                                            Personal Information
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="firstname"
                                            label="First Name"
                                            autoComplete="firstname"
                                            size="small"
                                            fullWidth
                                            value={formik.values.firstname}
                                            onChange={formik.handleChange}
                                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                            helperText={formik.touched.firstname && formik.errors.firstname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="lastname"
                                            label="Last Name"
                                            autoComplete="lastname"
                                            size="small"
                                            fullWidth
                                            value={formik.values.lastname}
                                            onChange={formik.handleChange}
                                            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                            helperText={formik.touched.lastname && formik.errors.lastname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                        <TextField
                                            name="email"
                                            label="Email ID"
                                            autoComplete="email"
                                            size="small"
                                            fullWidth
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                        <TextField
                                            name="phoneNumber"
                                            label="Contact Number"
                                            autoComplete="phone"
                                            size="small"
                                            fullWidth
                                            value={formik.values.phoneNumber}
                                            onChange={formik.handleChange}
                                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>

                                        <TextField
                                            name="message"
                                            label="Write about your experience"
                                            autoComplete="message"
                                            size="small"
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            maxRows={5}
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            error={formik.touched.message && Boolean(formik.errors.message)}
                                            helperText={formik.touched.message && formik.errors.message}
                                        />

                                    </Grid>


                                    <Grid item xs={12} sx={{ mb: 3 }}>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                    </Grid>
                    <Grid item xs='auto' sx={{ textAlign: 'right' }}>
                        <Box>
                            {<img src="/images/ad.png" />}
                        </Box>
                    </Grid>

                </Grid>




            </Box >
        </Container>
    );
}

export default ContactUs;