import { useState } from 'react';
import {
    Container, Box, Grid, Paper, Rating,
    Card, CardContent, CardMedia, CardActions, CardActionArea,
    Typography, Divider, Button, Stack, Avatar,
    List, ListItem, ListItemIcon, ListItemText, TextField, IconButton, InputBase,
    FormControl, InputLabel, Select, MenuItem, Menu
} from '@mui/material';
import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';

import PublishIcon from '@mui/icons-material/Publish';


import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SchoolIcon from '@mui/icons-material/School';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PeopleIcon from '@mui/icons-material/People';

import DoneIcon from '@mui/icons-material/Done';

import ContactUs from '../Home/ContactUs.js';
import Search from '../Search/index.js';

import Navbar from '../Navbar/Navbar.js';
import Subscribe from '../Subscribe/index.js';
import Subscribe1 from '../Subscribe/index.js';
import Footer from '../Footer/Footer.js';

const freeFeatures = [
    'Photo booth',
    'Virtual visit',
    'My DayCare Verfied business logo',
    'About Us',
    'About us - 5 parts about 150-250 words each',
    'Our Story',
    'Our vision Mission',
    'Your Child Experience',
    'About the Kitchen',

]



const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ffffff',
    },
    '& .MuiRating-iconHover': {
        color: '#ffffff',
    },
});

function RecreationalMembership() {

    const [openSigninModal, setOpenSigninModal] = useState(false);

    const handleSigninModal = () => {
        console.log('yes')
        setOpenSigninModal(true)
    }

    return (
        <>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>

            <Box sx={{
                height: '134px',
                backgroundImage: 'url(../images/ads-banner-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        width: '100%', height: '100%'
                    }}
                >
                    {<img src='/images/ad_banner_h.png' alt="Ads" />}
                </Box>
            </Box >



            <Container maxWidth="lg" >

                <Box>
                    <Stack direction='row' spacing={5}>
                        <Typography variant="h1" color="text.primary" sx={{ pt: 3 }}>
                            Recreational Membership
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {<img src="/images/icons/butterfly.png" />}
                        </Box>

                    </Stack>

                    <Divider sx={{ maxWidth: '150px', borderColor: 'text.secondary', mb: 5 }} flexItem />

                    <Grid container rowSpacing={6} columnSpacing={2} justifyContent='center'>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ border: 2, borderRadius: 3, p: 3 }}>
                                <Typography variant='h4' color="text.dark">
                                    Free
                                </Typography>
                                <Stack direction='row' alignItems='baseline'>
                                    <Typography sx={{ fontSize: '60px', fontWeight: 'bold' }} >
                                        $0
                                    </Typography>
                                    <Typography sx={{ fontSize: '29px' }}>
                                        /mo
                                    </Typography>
                                </Stack>
                                <Typography variant='h5' color='text.dark' sx={{ fontWeight: '100', mb: 3 }}>
                                    Lorem Ipsum and pir se Lorum pipsum
                                </Typography>

                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Start
                                </Button>

                                <Typography variant='h4' color='text.dark' sx={{ mt: 3 }}>
                                    Features
                                </Typography>

                                <List dense={true}>
                                    {freeFeatures.map((freeFeature) =>
                                        <ListItem sx={{ pl: 0 }}>
                                            <ListItemIcon sx={{ minWidth: '30px' }}>
                                                <img src={`/images/icons/rounded_tick.png`} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{ fontSize: '1.5rem' }}>{freeFeature}</Typography>}
                                                sx={{ color: 'text.dark' }}
                                            />
                                        </ListItem>
                                    )}
                                </List>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ border: 2, borderRadius: 3, p: 3 }}>
                                <Typography variant='h4' color="text.dark">
                                    Medium
                                </Typography>
                                <Stack direction='row' alignItems='baseline'>
                                    <Typography sx={{ fontSize: '60px', fontWeight: 'bold' }} >
                                        $10
                                    </Typography>
                                    <Typography sx={{ fontSize: '29px' }}>
                                        /mo
                                    </Typography>
                                </Stack>
                                <Typography variant='h5' color='text.dark' sx={{ fontWeight: '100', mb: 3 }}>
                                    Lorem Ipsum and pir se Lorum pipsum
                                </Typography>

                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Start
                                </Button>

                                <Typography variant='h4' color='text.dark' sx={{ mt: 3 }}>
                                    Features
                                </Typography>

                                <List dense={true}>
                                    {freeFeatures.map((freeFeature) =>
                                        <ListItem sx={{ pl: 0 }}>
                                            <ListItemIcon sx={{ minWidth: '30px' }}>
                                                <img src={`/images/icons/rounded_tick.png`} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{ fontSize: '1.5rem' }}>{freeFeature}</Typography>}
                                                sx={{ color: 'text.dark' }}
                                            />
                                        </ListItem>
                                    )}
                                </List>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ border: 2, borderRadius: 3, p: 3 }}>
                                <Typography variant='h4' color="text.dark">
                                    Platinum
                                </Typography>
                                <Stack direction='row' alignItems='baseline'>
                                    <Typography sx={{ fontSize: '60px', fontWeight: 'bold' }} >
                                        $20
                                    </Typography>
                                    <Typography sx={{ fontSize: '29px' }}>
                                        /mo
                                    </Typography>
                                </Stack>
                                <Typography variant='h5' color='text.dark' sx={{ fontWeight: '100', mb: 3 }}>
                                    Lorem Ipsum and pir se Lorum pipsum
                                </Typography>

                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Start
                                </Button>

                                <Typography variant='h4' color='text.dark' sx={{ mt: 3 }}>
                                    Features
                                </Typography>

                                <List dense={true}>
                                    {freeFeatures.map((freeFeature) =>
                                        <ListItem sx={{ pl: 0 }}>
                                            <ListItemIcon sx={{ minWidth: '30px' }}>
                                                <img src={`/images/icons/rounded_tick.png`} />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={<Typography sx={{ fontSize: '1.5rem' }}>{freeFeature}</Typography>}
                                                sx={{ color: 'text.dark' }}
                                            />
                                        </ListItem>
                                    )}
                                </List>

                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Box sx={{ border: 2, borderRadius: 3, px: 25, py: 5, mb: 5, textAlign: 'center' }}>
                                <Typography variant='h4' color="text.dark" sx={{ mb: 3 }}>
                                    Enterprise
                                </Typography>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Please Contact
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Container>

            <Footer />

        </>
    );
}

export default RecreationalMembership;