import { useState } from 'react';

import { Box, Menu, MenuItem, ListItemIcon, Stack, Button, SvgIcon, Typography } from "@mui/material";

import { ReactComponent as StarIcon } from '../../icons/1_1.svg';
import { Link } from 'react-router-dom';


const ProgramMenu = [
    { title: 'Daycare Centre', icon: 'daycare_centre' },
    { title: 'Licence Home Daycare', icon: 'license_home_daycare' },
    { title: 'Home/Family Daycare', icon: 'home_family_daycare' },
    { title: 'B&A School Care', icon: 'b&a_school_care' },
    { title: 'Full Time', icon: 'full_time' },
    { title: 'Part Time', icon: 'part_time' },
    { title: 'Licence', icon: 'licence' },
    { title: 'Subsidies/Financial Aid', icon: 'subsidies_financial_aid' },
    { title: 'Summer Camp', icon: 'summer_camp' },
    { title: 'Online', icon: 'online' },
];

const AgeMenu = [
    { title: 'Infants 0-18M', icon: 'infants_0_18m' },
    { title: 'Toddlers 18M-30M', icon: 'toddlers_18m_30m' },
    { title: 'Preschool 30M-6Y', icon: 'preschool_30m_6y' },
    { title: 'Kindergarten 4Y+', icon: 'kindergarten_4y' },
]

const PhilosophyMenu = [
    { title: 'Academic Based', icon: 'academic_based' },
    { title: 'Play-Based', icon: 'play_based' },
    { title: 'Montessori', icon: 'montessori' },
    { title: 'Waldorf', icon: 'waldorf' },
    { title: 'Reggio Emilia', icon: 'reggio_emilia' },
    { title: 'Community-Based', icon: 'community_based' },
    { title: 'Faith-Based', icon: 'faith_based' },
    { title: 'HighScope', icon: 'high_scope' },
    { title: 'Mother Goose', icon: 'mother_goose' },
    { title: 'Bank Street', icon: 'bank_street' },
    { title: 'Forest School', icon: 'forest_school' },

]

const MenuMenu = [
    { title: 'Fresh Cooked', icon: 'fresh_cooked' },
    { title: 'Paleo', icon: 'paleo' },
    { title: 'Vegan', icon: 'vegan' },
    { title: 'Vegetarian', icon: 'vegetarian' },
    { title: 'Organic', icon: 'organic' },
    { title: 'Kosher', icon: 'kosher' },
    { title: 'Halal', icon: 'halal' },
]

const ExtrasMenu = [
    { title: 'Yoga', icon: 'yoga' },
    { title: 'Music', icon: 'music' },
    { title: 'Cooking', icon: 'cooking' },
    { title: 'Languages', icon: 'languages' },
    { title: 'Vosual Arts', icon: 'vosual_arts' },
    { title: 'STEM', icon: 'stem' },
    { title: 'Math', icon: 'math' },
    { title: 'Art', icon: 'art' },
    { title: 'Drama', icon: 'drama' },
]

const LanguageMenu = [
    { title: 'English', icon: 'english' },
    { title: 'French', icon: 'french' },
    { title: 'Spanish', icon: 'spanish' },
    { title: 'Mandarin', icon: 'mandarin' },
    { title: 'Tagalog', icon: 'tagalog' },
    { title: 'Vietnamese', icon: 'vietnamese' },
    { title: 'Urdu', icon: 'urdu' },
    { title: 'Arabic', icon: 'arabic' },
    { title: 'Russian', icon: 'russian' },
    { title: 'Sign Language', icon: 'sign_language' },
]
const DistanceMenu = [
    { title: '1-3 Miles', icon: '1_3miles' },
    { title: '3-6 Miles', icon: '3_6miles' },
    { title: '6-9 Miles', icon: '6_9miles' },
    { title: '10+ Miles', icon: '10_miles' },
]

const InclusionMenu = [
    { title: 'Special Diets', icon: 'special_diets' },
    { title: 'Neurological Diversity', icon: 'neurological_diversity' },
    { title: 'Physical Diversity', icon: 'physical_diversity' },
    { title: 'Social and Emotional Diversity', icon: 'social_and_emotional_diversity' },
    { title: 'Hearing Diversity', icon: 'hearing_diversity' },
    { title: 'Visual Diversity', icon: 'visual_diversity' },
]

const AdvancedSearch = (props) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);
    const open5 = Boolean(anchorEl5);
    const open6 = Boolean(anchorEl6);
    const open7 = Boolean(anchorEl7);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClick4 = (event) => {
        setAnchorEl4(event.currentTarget);
    };
    const handleClick5 = (event) => {
        setAnchorEl5(event.currentTarget);
    };
    const handleClick6 = (event) => {
        setAnchorEl6(event.currentTarget);
    };
    const handleClick7 = (event) => {
        setAnchorEl7(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl1(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
        setAnchorEl4(null);
        setAnchorEl5(null);
        setAnchorEl6(null);
        setAnchorEl7(null);
    };

    return (

        <Box sx={{ px: 3, mt: 3 }}>
            <Typography variant="h3" color={props.color}>
                Advance Search
            </Typography>
            <Stack direction='row' spacing={4} sx={{ mt: 1, mb: 3, textAlign: 'center', alignItems: 'baseline' }}>
                <Box onClick={handleClick}>
                    {<img src="/images/icons/1.png" />}
                    <Typography variant='h5' color="text.white">
                        Program
                    </Typography>
                </Box>

                <Menu
                    id="program-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {ProgramMenu.map((program, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/program/${program.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {program.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>

                <Box onClick={handleClick1}>
                    {<img src="/images/icons/2.png" />}
                    <Typography variant='h5' color="text.white">
                        Age
                    </Typography>
                </Box>

                <Menu
                    id="age-menu"
                    anchorEl={anchorEl1}
                    open={open1}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {AgeMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/age/${age.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>

                <Box onClick={handleClick2}>
                    {<img src="/images/icons/3.png" />}
                    <Typography variant='h5' color="text.white">
                        Philosophy
                    </Typography>
                </Box>

                <Menu
                    id="philosophy-menu"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {PhilosophyMenu.map((philosophy, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/philosophy/${philosophy.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {philosophy.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>

                <Box onClick={handleClick3}>
                    {<img src="/images/icons/4.png" />}
                    <Typography variant='h5' color="text.white">
                        Menu
                    </Typography>
                </Box>
                <Menu
                    id="menu-menu"
                    anchorEl={anchorEl3}
                    open={open3}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {MenuMenu.map((menu, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/menu/${menu.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {menu.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>
                <Box onClick={handleClick4}>
                    {<img src="/images/icons/5.png" />}
                    <Typography variant='h5' color="text.white">
                        Extras
                    </Typography>
                </Box>
                <Menu
                    id="extras-menu"
                    anchorEl={anchorEl4}
                    open={open4}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {ExtrasMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/extras/${age.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box></MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>
                <Box onClick={handleClick5}>
                    {<img src="/images/icons/6.png" />}
                    <Typography variant='h5' color="text.white">
                        Language
                    </Typography>
                </Box>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl5}
                    open={open5}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {LanguageMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/language/${age.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>

                <Box onClick={handleClick6}>
                    {<img src="/images/icons/7.png" />}
                    <Typography variant='h5' color="text.white">
                        Distance
                    </Typography>
                </Box>
                <Menu
                    id="distance-menu"
                    anchorEl={anchorEl6}
                    open={open6}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {DistanceMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/distance/${age.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>
                <Box onClick={handleClick7}>
                    {<img src="/images/icons/8.png" />}
                    <Typography variant='h5' color="text.white">
                        Inclusion
                    </Typography>
                </Box>
                <Menu
                    id="inclusion-menu"
                    anchorEl={anchorEl7}
                    open={open7}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {InclusionMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <img src={`/images/icons/menu/inclusion/${age.icon}.png`} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h6"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                    <MenuItem
                        sx={{
                            pt: 1, justifyContent: 'center'
                        }}
                    >
                        <img src={`/images/icons/menu/up_arrow.png`} />

                    </MenuItem>
                </Menu>
            </Stack>

            {props.buttonStyle == 'white' ?
                <Button
                    variant="white"
                    size="large"
                    component={Link}
                    to="/SearchResult"
                >
                    Search
                </Button> :
                <Box sx={{ textAlign: 'center' }}>
                    <Button
                        variant="contained"
                        size="large"
                        type="submit"
                    >
                        Search
                    </Button>

                </Box>
            }
        </Box>
    )
}

export default AdvancedSearch;