import { useState } from 'react';
import {
    Container, Box, Grid, Paper, Rating,
    Card, CardContent, CardMedia, CardActions, CardActionArea,
    Typography, Divider, Button, Stack, Avatar,
    List, ListItem, ListItemIcon, ListItemText, TextField, IconButton, InputBase,
    FormControl, InputLabel, Select, MenuItem, Menu
} from '@mui/material';
import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';

import PublishIcon from '@mui/icons-material/Publish';


import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SchoolIcon from '@mui/icons-material/School';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PeopleIcon from '@mui/icons-material/People';

import DoneIcon from '@mui/icons-material/Done';

import ContactUs from '../Home/ContactUs.js';
import Search from '../Search/index.js';

import Navbar from '../Navbar/Navbar.js';
import Subscribe from '../Subscribe/index.js';
import Subscribe1 from '../Subscribe/index.js';
import Footer from '../Footer/Footer.js';



const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ffffff',
    },
    '& .MuiRating-iconHover': {
        color: '#ffffff',
    },
});

const OurTeam = () => {

    return (
        <>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>

            <Box sx={{
                height: '134px',
                backgroundImage: 'url(./images/ads-banner-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        width: '100%', height: '100%'
                    }}
                >
                    {<img src='/images/ad_banner_h.png' alt="Ads" />}
                </Box>
            </Box>


            <Container maxWidth="lg" >

                <Box sx={{ py: 2 }}>

                    <Box>
                        <Stack direction='row' spacing={5}>
                            <Typography variant="h1" color="text.yellow" sx={{ pt: 3 }}>
                                Our team
                            </Typography>
                            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {<img src="/images/icons/butterfly.png" />}
                            </Box> */}

                        </Stack>
                        {/* <Divider sx={{ maxWidth: '150px', borderColor: 'text.secondary' }} flexItem /> */}
                    </Box>


                    <Grid container direction="row" columnSpacing={5} sx={{ mb: 5 }}>

                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FFB200',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    mt: 3
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'/images/team/team1.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Mina Mina
                                            </Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Education:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Experience:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                5 years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Country:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Lorem Ipsum:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>


                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FF5C49',
                                    borderRadius: '10px',
                                    textAlign: 'center'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'/images/team/team2.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Mina Mina
                                            </Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Education:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Experience:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                5 years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Country:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Lorem Ipsum:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#6BC53C',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                    mt: 3
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'/images/team/team3.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Mina Mina
                                            </Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Education:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Experience:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                5 years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Country:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Lorem Ipsum:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                    </Grid>
                </Box>
            </Container>

            <Box sx={{ p: 3 }}>
                {<img src="/images/team/team_page_ad.png" width='100%' />}
            </Box>


            <Container maxWidth="lg" >

                <Box sx={{ py: 2 }}>

                    <Grid container direction="row" columnSpacing={5}>

                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FFB200',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'/images/team/team3.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Mina Mina
                                            </Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Education:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Experience:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                5 years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Country:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Lorem Ipsum:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>


                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FF5C49',
                                    borderRadius: '10px',
                                    textAlign: 'center'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'/images/team/team4.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Mina Mina
                                            </Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Education:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Experience:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                5 years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Country:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Lorem Ipsum:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#6BC53C',
                                    borderRadius: '10px',
                                    textAlign: 'center',
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'/images/team/team2.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Mina Mina
                                            </Typography>

                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Education:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Experience:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                5 years
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Country:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="text.white" sx={{ fontWeight: 900, fontSize: '1.3rem', textAlign: 'left' }}>
                                                Lorem Ipsum:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs='auto'>
                                            <Typography color="text.white" sx={{ fontSize: '1.3rem' }}>
                                                Masters in child psychology
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider sx={{ borderColor: 'text.white' }} />
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                    </Grid>
                </Box >

            </Container >


            <Footer />

        </>
    );
}

export default OurTeam;