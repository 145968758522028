import {
    Container, Box, Grid, Paper, Rating,
    Card, CardContent, CardMedia, CardActions, CardActionArea,
    Typography, Divider, Button, Stack, Avatar,
    List, ListItem, ListItemIcon, ListItemText, TextField, IconButton, InputBase,
    FormControl, InputLabel, Select, MenuItem, Menu
} from '@mui/material';

const Subscribe = () => {
    return (

        <Box sx={{
            my: 3,
            backgroundImage: 'url(/images/subscribe-bg-1.png)',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}
        >
            <Box sx={{
                height: '100%',
                background: 'rgba(0, 0, 0, 0.6)',
            }}>

                <Grid
                    container
                    sx={{
                        backgroundImage: 'url(/images/subscribe-bg-2.png)',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        py: 4,
                    }}>

                    <Grid item xs={12}>
                        <Typography variant="h3" color="text.white" sx={{ textAlign: 'center' }}>
                            Signup for Newsletter
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction='row' spacing={3} alignItems='center' justifyContent='center'>
                            <TextField
                                name="email"
                                label="Email address to my Daycare"
                                autoComplete="email"
                                fullWidth
                                size='small'
                                sx={{ maxWidth: '180px' }}
                            />
                            <Button
                                variant="contained"
                                size="small"
                                type="submit"
                            >
                                Register
                            </Button>
                        </Stack>

                    </Grid>

                </Grid>
            </Box>
        </Box>

    )
}

export default Subscribe;