import {
    Container, Box, Grid, Rating,
    Card, CardMedia, CardActions, CardActionArea,
    Typography, Divider, Stack,
    List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';

import Search from '../Search';
import AdvancedSearch from '../Search/Advanced';
import Navbar from '../Navbar/Navbar';
import Subscribe from '../Subscribe';
import WriteReview from './WriteReview.js';



const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ffffff',
    },
    '& .MuiRating-iconHover': {
        color: '#ffffff',
    },
});

function MainPage() {



    return (
        <>
            <Box sx={{
                minHeight: '100vh',
                backgroundImage: 'url(./images/main-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}
            >
                <Grid
                    container
                    sx={{
                        height: '100%',
                        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 1.25%, rgba(0, 0, 0, 0) 83.11%)',

                    }}>

                    <Grid item xs={12}>
                        <Container maxWidth="lg">
                            <Navbar />
                            <Search />
                            <AdvancedSearch color="text.white" buttonStyle="white" />
                        </Container>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'center', mt: 20, mb: 10 }}>
                        {<img src='/images/ad_banner_h.png' alt="Ads" />}
                    </Grid>
                </Grid>
            </Box >

            <Box sx={{
                mt: 2,
                height: '134px',
                backgroundImage: 'url(./images/ads-banner-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        width: '100%', height: '100%'
                    }}
                >
                    {<img src='/images/ad_banner_h.png' alt="Ads" />}
                </Box>
            </Box>



            <Container maxWidth="lg" >

                <Box sx={{ py: 2 }}>

                    <Box data-aos="fade-right">
                        <Stack direction='row' spacing={5}>
                            <Typography variant="h1" color="text.primary" sx={{ pt: 3 }}>
                                Daycare in your area
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {<img alt="" src="/images/icons/butterfly.png" />}
                            </Box>

                        </Stack>
                        <Divider sx={{ maxWidth: '150px', borderColor: 'text.secondary' }} flexItem />
                    </Box>


                    <Grid container direction="row" columnSpacing={5} sx={{ my: 5 }}>

                        <Grid item xs={12} md={4} data-aos="fade-right">
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FFB200',
                                    borderRadius: '10px',
                                    textAlign: 'center'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'./images/card.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Indoor play area
                                            </Typography>
                                            <Typography variant="body2" color="text.white">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.white">
                                                My Daycare
                                            </Typography>
                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.white">
                                                Google
                                            </Typography>
                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        2-5
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Years Old
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        22
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Class Size
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        15
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Items
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                        <Grid item xs={12} md={4} data-aos="fade-right">
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FF5C49',
                                    borderRadius: '10px',
                                    textAlign: 'center'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'./images/card.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Indoor play area
                                            </Typography>
                                            <Typography variant="body2" color="text.white">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.white">
                                                My Daycare
                                            </Typography>
                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.white">
                                                Google
                                            </Typography>
                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        2-5
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Years Old
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        22
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Class Size
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        15
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Items
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                        <Grid item xs={12} md={4} data-aos="fade-right">
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#6BC53C',
                                    borderRadius: '10px',
                                    textAlign: 'center'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'./images/card.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 3, py: 2 }}>

                                    <Grid container direction="row" spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                            >
                                                Indoor play area
                                            </Typography>
                                            <Typography variant="body2" color="text.white">
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.white">
                                                My Daycare
                                            </Typography>
                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="body2" color="text.white">
                                                Google
                                            </Typography>
                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        2-5
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Years Old
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        22
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Class Size
                                                    </Typography>
                                                </Grid>
                                                <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                <Grid item xs>
                                                    <Typography variant="body1" color="text.white">
                                                        15
                                                    </Typography>
                                                    <Typography variant="body1" color="text.white">
                                                        Items
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                    </Grid>
                </Box>

            </Container >

            <Subscribe />


            <Container maxWidth="lg" >

                <Box>
                    <Box data-aos="fade-right">
                        <Stack direction='row' spacing={5}>
                            <Typography variant="h1" color="text.primary" sx={{ pt: 3 }}>
                                Featured Recreational Vendors
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {<img alt="" src="/images/icons/kid_in_bench.png" />}
                            </Box>

                        </Stack>
                        <Divider sx={{ maxWidth: '150px', borderColor: 'text.secondary' }} flexItem />

                    </Box>


                    <Grid container direction="row" columnSpacing={4} sx={{ my: 5 }}>

                        <Grid item xs={12} md={4} data-aos="fade-right">
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#6BC53C',
                                    borderRadius: '10px'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'./images/card.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 2, py: 0 }}>

                                    <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                                sx={{ textAlign: 'center' }}
                                            >
                                                Vendor Name
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                    <Typography
                                                        color="text.white"
                                                        sx={{
                                                            textAlign: 'justify', mb: 2,
                                                            fontSize: '1rem',
                                                            lineHeight: 1.2
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                    </Typography>
                                                    <Stack direction='row' justifyContent='space-between' sx={{ mb: 1 }}>
                                                        <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                            2-5 years old
                                                        </Typography>
                                                        <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                            Town Name
                                                        </Typography>
                                                    </Stack>
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography variant="body2" color="text.white">
                                                            My Daycare
                                                        </Typography>
                                                        <StyledRating name="read-only" value={4} size="small" readOnly />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs='auto'>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                </Grid>

                                                <Grid item xs>
                                                    <Typography variant="h5" color="text.white">
                                                        Core Activities
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <Box>
                                                            <List sx={{ p: 0 }}>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Walking"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Reading"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Meditation"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Box>
                                                        <Box>
                                                            <List sx={{ p: 0 }}>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Swimming"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Games"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Dancing"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </List>

                                                        </Box>
                                                    </Stack>

                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography variant="body2" color="text.white">
                                                            Google
                                                        </Typography>
                                                        <StyledRating name="read-only" value={3} size="small" readOnly />
                                                    </Box>

                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                        <Grid item xs={12} md={4} data-aos="fade-right">
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FF5C49',
                                    borderRadius: '10px'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'./images/card.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 2, py: 0 }}>

                                    <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                                sx={{ textAlign: 'center' }}
                                            >
                                                Vendor Name
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                    <Typography
                                                        color="text.white"
                                                        sx={{
                                                            textAlign: 'justify', mb: 2,
                                                            fontSize: '1rem',
                                                            lineHeight: 1.2
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                    </Typography>
                                                    <Stack direction='row' justifyContent='space-between' sx={{ mb: 1 }}>
                                                        <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                            2-5 years old
                                                        </Typography>
                                                        <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                            Town Name
                                                        </Typography>
                                                    </Stack>
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography variant="body2" color="text.white">
                                                            My Daycare
                                                        </Typography>
                                                        <StyledRating name="read-only" value={4} size="small" readOnly />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs='auto'>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                </Grid>

                                                <Grid item xs>
                                                    <Typography variant="h5" color="text.white">
                                                        Core Activities
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <Box>
                                                            <List sx={{ p: 0 }}>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Walking"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Reading"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Meditation"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Box>
                                                        <Box>
                                                            <List sx={{ p: 0 }}>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Swimming"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Games"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Dancing"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </List>

                                                        </Box>
                                                    </Stack>

                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography variant="body2" color="text.white">
                                                            Google
                                                        </Typography>
                                                        <StyledRating name="read-only" value={3} size="small" readOnly />
                                                    </Box>

                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                        <Grid item xs={12} md={4} data-aos="fade-right">
                            <Card
                                elevation={2}
                                sx={{
                                    height: '100%', transition: '.3s',
                                    ":hover": { transform: 'translateY(rem)' },
                                    background: '#FFB200',
                                    borderRadius: '10px'
                                }} >

                                <CardActionArea component={Link} to={'/'}>
                                    <CardMedia
                                        component="img"
                                        image={'./images/card.png'}
                                    />


                                </CardActionArea>
                                <CardActions sx={{ px: 2, py: 0 }}>

                                    <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h3"
                                                color="text.white"
                                                sx={{ textAlign: 'center' }}
                                            >
                                                Vendor Name
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                    <Typography
                                                        color="text.white"
                                                        sx={{
                                                            textAlign: 'justify', mb: 2,
                                                            fontSize: '1rem',
                                                            lineHeight: 1.2
                                                        }}
                                                    >
                                                        Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                    </Typography>
                                                    <Stack direction='row' justifyContent='space-between' sx={{ mb: 1 }}>
                                                        <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                            2-5 years old
                                                        </Typography>
                                                        <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                            Town Name
                                                        </Typography>
                                                    </Stack>
                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography variant="body2" color="text.white">
                                                            My Daycare
                                                        </Typography>
                                                        <StyledRating name="read-only" value={4} size="small" readOnly />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs='auto'>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                </Grid>

                                                <Grid item xs>
                                                    <Typography variant="h5" color="text.white">
                                                        Core Activities
                                                    </Typography>
                                                    <Stack direction="row" spacing={1}>
                                                        <Box>
                                                            <List sx={{ p: 0 }}>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Walking"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Reading"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Meditation"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </List>
                                                        </Box>
                                                        <Box>
                                                            <List sx={{ p: 0 }}>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Swimming"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Games"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                                <ListItem disableGutters sx={{ py: 0 }}>
                                                                    <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                        <img alt="" src={`/images/icons/tick.png`} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary="Dancing"
                                                                        primaryTypographyProps={{
                                                                            fontSize: '1rem',
                                                                            color: 'text.white'
                                                                        }}
                                                                    />
                                                                </ListItem>
                                                            </List>

                                                        </Box>
                                                    </Stack>

                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Typography variant="body2" color="text.white">
                                                            Google
                                                        </Typography>
                                                        <StyledRating name="read-only" value={3} size="small" readOnly />
                                                    </Box>

                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardActions>
                            </Card>

                        </Grid>

                    </Grid>
                </Box>

            </Container>

            <WriteReview />


        </>
    );
}

export default MainPage;