
import { Box, Paper, InputBase, IconButton, Divider, Typography } from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const Search = () => {
    return (
        <Box sx={{ px: 3 }}>

            <Typography variant="h1" color="text.white">
                Your Child Care Search Made Easy
            </Typography>

            <Paper
                component="form"
                sx={{ pl: 1, display: 'flex', alignItems: 'center', width: 800, borderRadius: '50px' }}
            >
                <InputBase
                    startAdornment={<HomeOutlinedIcon color='red' sx={{ mr: 2 }} />}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search For Daycare"
                    inputProps={{ 'aria-label': 'search daycare' }}
                    size='50px'
                />
                <Divider sx={{ height: 28, m: 0.5, borderColor: '#FF5B4A' }} orientation="vertical" />
                <InputBase
                    startAdornment={<LocationOnOutlinedIcon color='red' sx={{ mr: 2 }} />}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Toronto"
                    inputProps={{ 'aria-label': 'search location' }}
                />
                <IconButton type="button" sx={{ p: 2, backgroundColor: '#FF5B4A', borderRadius: '0 50px 50px 0' }} aria-label="search">
                    <SearchIcon color='white' />
                </IconButton>

            </Paper>
        </Box>
    )
}

export default Search;