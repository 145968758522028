import { useFormik } from "formik";
import * as yup from 'yup';

import {
    Container, Box, Grid, Paper, Rating,
    Typography, Divider, Button, Stack, TextField
} from '@mui/material';

import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import PersonIcon from '@mui/icons-material/Person';


import Search from "../../Search";
import Navbar from "../../Navbar/Navbar";
import Subscribe from "../../Subscribe";
import Footer from "../../Footer/Footer";


const validationSchema = yup.object({
    firstname: yup.string()
        .required('first name required'),
    lastname: yup.string()
        .required('last name required'),
    email: yup.string()
        .email('invalid email')
        .required('email required'),
    phoneNumber: yup.string()
        .required('contact number required'),
    age: yup.string()
        .required('age required'),
    subject: yup.string()
        .required("subject required"),
    message: yup.string()
        .required("message required"),
});

function RecreationalBusinessFree() {

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phoneNumber: '',
            age: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            //await api.contactUs(values);
        },
    });


    return (
        <>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>
            <Grid
                container
                sx={{
                    height: '100%',
                }}>

                <Grid item xs={12}>
                    <Container maxWidth="lg">
                        <Stack direction='row' spacing={3} alignItems='center' sx={{ my: 5 }}>
                            <Box
                                component="img"
                                src="/images/business_header_icon.png"
                            />
                            <Typography variant="h1" color="text.dark" >
                                Business Name Here
                            </Typography>
                            <Box
                                component="img"
                                height='fit-content'
                                src="/images/white_pet.png"
                            />
                        </Stack>
                    </Container>
                </Grid>
            </Grid>

            <Container maxWidth="lg" sx={{ my: 3 }}>

                <Grid container spacing={2}>

                    <Grid item xs={12} md={7}>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>

                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h4" color="text.dark" sx={{}}>
                                        My Daycare Reviews
                                    </Typography>
                                    <Rating
                                        name="daycare"
                                        value={4}
                                        size="large"
                                        readOnly
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h4" color="text.dark" sx={{}}>
                                        Google Reviews
                                    </Typography>
                                    <Rating
                                        name="google"
                                        value={5}
                                        size="large"
                                        readOnly
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction='row' spacing={5} sx={{ mb: 3 }}>
                                    <Typography variant="h5" color="text.dark">
                                        Age:
                                        <Box component="span" sx={{ fontWeight: '100' }}>2-5</Box>
                                    </Typography>

                                    <Typography variant="h5" color="text.dark">
                                        Program:
                                        <Box component="span" sx={{ fontWeight: '100' }}>Full Day, Licenced</Box>
                                    </Typography>
                                    <Typography variant="h5" color="text.dark">
                                        Hours:
                                        <Box component="span" sx={{ fontWeight: '100' }}>6 am - 6 pm</Box>
                                    </Typography>
                                </Stack>

                                <Stack direction='row' spacing={5} sx={{ mb: 3 }}>
                                    <Typography variant="h5" color="text.dark">
                                        Distance:
                                        <Box component="span" sx={{ fontWeight: '100' }}>Full Day, Licenced</Box>
                                    </Typography>
                                    <Typography variant="h5" color="text.dark">
                                        Educational philosophy:
                                        <Box component="span" sx={{ fontWeight: '100' }}>Montessori</Box>
                                    </Typography>
                                    <Typography variant="h5" color="text.dark">
                                        More
                                    </Typography>
                                </Stack>

                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={5}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>

                            </Grid>
                            <Grid item xs={12} md={8}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ mb: 2 }}
                                    fullWidth
                                >
                                    Add a Review
                                </Button>

                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <EmailIcon fontSize="small" />
                                    <Typography variant="body2" color="text.dark">Please click to email</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <PlaceIcon fontSize="small" />
                                    <Typography variant="body1" color="text.dark">24 Davis Dr, Newmarket L3U 7T5</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center" >
                                    <LanguageIcon fontSize="small" />
                                    <Typography variant="body1" color="text.dark">www.daycare.com</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="center">
                                    <PersonIcon fontSize="small" />
                                    <Typography variant="body1" color="text.dark">Owner: Mina Mina</Typography>
                                </Stack>

                                <Stack direction="row" alignItems="center" spacing={3}>
                                    <Box
                                        component="img"
                                        src="/images/icons/twitter_orange.png"
                                    />
                                    <Box
                                        component="img"
                                        src="/images/icons/facebook_orange.png"
                                    />
                                    <Box
                                        component="img"
                                        src="/images/icons/tiktok_orange.png"
                                    />
                                    <Box
                                        component="img"
                                        src="/images/icons/instagram_orange.png"
                                    />

                                </Stack>


                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Box sx={{
                            border: '1px solid #FF5D49',
                            width: "100%", height: "100%",
                            backgroundImage: 'url(/images/timing-bg.png)',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            p: 2
                        }}>

                            <Typography variant="h2" sx={{ mb: 3 }}>
                                Hours
                            </Typography>

                            <Grid container>
                                <Grid item xs={3}>
                                    <Typography variant="h4" color="text.dark">Mon</Typography>
                                    <Typography variant="h4" color="text.dark">Tue</Typography>
                                    <Typography variant="h4" color="text.dark">Wed</Typography>
                                    <Typography variant="h4" color="text.dark">Thu</Typography>
                                    <Typography variant="h4" color="text.dark">Fri</Typography>
                                    <Typography variant="h4" color="text.dark">Sat</Typography>
                                    <Typography variant="h4" color="text.dark">Sun</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    <Typography variant="h4" color="text.dark" sx={{ fontWeight: "100" }}>
                                        7:00 am - 6:00 pm
                                    </Typography>
                                    <Typography variant="h4" color="text.dark" sx={{ fontWeight: "100" }}>
                                        7:00 am - 6:00 pm
                                    </Typography>
                                    <Typography variant="h4" color="text.dark" sx={{ fontWeight: "100" }}>
                                        7:00 am - 6:00 pm
                                    </Typography>
                                    <Typography variant="h4" color="text.dark" sx={{ fontWeight: "100" }}>
                                        7:00 am - 6:00 pm
                                    </Typography>
                                    <Typography variant="h4" color="text.dark" sx={{ fontWeight: "100" }}>
                                        7:00 am - 6:00 pm
                                    </Typography>
                                    <Typography variant="h4" color="text.dark" sx={{ fontWeight: "100" }}>
                                        Closed
                                    </Typography>
                                    <Typography variant="h4" color="text.dark" sx={{ fontWeight: "100" }}>
                                        Closed
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>

                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box sx={{
                            border: '1px solid #FF5D49',
                            width: "100%", height: "100%",
                            backgroundImage: 'url(/images/business-details-bg.png)',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            p: 2
                        }}>
                            <Grid container sx={{ height: '100%' }}>
                                <Grid item xs sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/1.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Program:
                                            <Box component="span" sx={{ fontWeight: '100' }}>Daycare Centre, Licence Home Daycare</Box>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/2.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Age:
                                            <Box component="span" sx={{ fontWeight: '100' }}>Infants 0-18M, Toddlers 18M-30M</Box>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/3.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Philosophy:
                                            <Box component="span" sx={{ fontWeight: '100' }}>Academic Based, Play-Based</Box>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/4.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Menu:
                                            <Box component="span" sx={{ fontWeight: '100' }}>Faith-Based, HighScope</Box>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/5.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Extras:
                                            <Box component="span" sx={{ fontWeight: '100' }}>Yoga, Music</Box>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/6.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Language:
                                            <Box component="span" sx={{ fontWeight: '100' }}>English, French</Box>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/7.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Distance:
                                            <Box component="span" sx={{ fontWeight: '100' }}>1-3 Miles, 6-9 Miles</Box>
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {<img alt="" src="/images/icons/8.png" style={{ width: '8%' }} />}
                                        <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                            Inclusion:
                                            <Box component="span" sx={{ fontWeight: '100' }}>Special Diets, Neurological Diversity</Box>
                                        </Typography>
                                    </Stack>
                                </Grid>

                                <Divider orientation="vertical" sx={{ mx: 2 }} flexItem />

                                <Grid item xs="auto" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                        Child Capacity:
                                        <Box component="span" sx={{ fontWeight: '100' }}>25</Box>
                                    </Typography>
                                    <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                        Facility Size:
                                        <Box component="span" sx={{ fontWeight: '100' }}>2,000 sq</Box>
                                    </Typography>
                                    <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                        Operating Since:
                                        <Box component="span" sx={{ fontWeight: '100' }}>01/01/1999</Box>
                                    </Typography>
                                    <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                        Licensing Number:
                                        <Box component="span" sx={{ fontWeight: '100' }}>123456 </Box>
                                    </Typography>
                                    <Typography variant="body2" color="text.dark" sx={{ fontWeight: '900' }}>
                                        License Expire:
                                        <Box component="span" sx={{ fontWeight: '100' }}>June 2023</Box>
                                    </Typography>

                                    <Button
                                        variant="contained"
                                        size="large"
                                    >
                                        Ministry Report
                                    </Button>

                                </Grid>
                            </Grid>


                        </Box>

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h4" color="text.dark" sx={{ mb: 2 }}>Our Photo Booth</Typography>
                        <Grid container>
                            <Grid item xs={12}>
                                {<img src="/images/gallery/1.png" alt="1" style={{ width: '100%' }} />}
                            </Grid>


                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ my: 3 }}>
                        <Button
                            variant="contained"
                            size="large"
                        >
                            Join a Waiting List
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ my: 3 }}>
                        <Button
                            variant="contained"
                            size="large"
                        >
                            Save to my Search Results
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ textAlign: 'right', my: 3 }}>
                        <Button
                            variant="contained"
                            size="large"
                        >
                            Add a Review
                        </Button>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Box>
                            {<img src="/images/business_free_ad_1.png" alt="ad" style={{ width: '100%' }} />}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Box sx={{ border: '1px solid #FF5D49' }} >
                            {<img src="/images/map.png" alt="map" style={{ width: '100%' }} />}
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <Subscribe />

            <Container sx={{ my: 5 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>

                        <Typography variant="h1" color="text.yellow">
                            Contact Us
                        </Typography>
                        <Typography variant="h3" color="text.dark">
                            Tell us about your requirements
                        </Typography>

                        <Paper elevation={1} sx={{ px: 5, py: 2, borderRadius: '10px' }}>

                            <Box component="form" onSubmit={formik.handleSubmit} sx={{ textAlign: 'left' }} >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="firstname"
                                            label="First Name"
                                            autoComplete="firstname"
                                            size="small"
                                            fullWidth
                                            value={formik.values.firstname}
                                            onChange={formik.handleChange}
                                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                            helperText={formik.touched.firstname && formik.errors.firstname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="lastname"
                                            label="Last Name"
                                            autoComplete="lastname"
                                            size="small"
                                            fullWidth
                                            value={formik.values.lastname}
                                            onChange={formik.handleChange}
                                            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                            helperText={formik.touched.lastname && formik.errors.lastname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                        <TextField
                                            name="email"
                                            label="Email ID"
                                            autoComplete="email"
                                            size="small"
                                            fullWidth
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                        <TextField
                                            name="phoneNumber"
                                            label="Contact Number"
                                            autoComplete="phone"
                                            size="small"
                                            fullWidth
                                            value={formik.values.phoneNumber}
                                            onChange={formik.handleChange}
                                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={6}>

                                        <TextField
                                            name="age"
                                            label="Kid Age"
                                            autoComplete="age"
                                            size="small"
                                            fullWidth
                                            value={formik.values.age}
                                            onChange={formik.handleChange}
                                            error={formik.touched.age && Boolean(formik.errors.age)}
                                            helperText={formik.touched.age && formik.errors.age}
                                        />

                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="h5" color="text.yellow">
                                            Add Kid
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={12} sm={6}>

                                        <Typography variant="h5" color="text.yellow">
                                            Write aboutyour requirements
                                        </Typography>

                                    </Grid>


                                    <Grid item xs={12}>

                                        <TextField
                                            name="message"
                                            label="Write about your experience"
                                            autoComplete="message"
                                            size="small"
                                            fullWidth
                                            multiline
                                            minRows={3}
                                            maxRows={5}
                                            value={formik.values.message}
                                            onChange={formik.handleChange}
                                            error={formik.touched.message && Boolean(formik.errors.message)}
                                            helperText={formik.touched.message && formik.errors.message}
                                        />

                                    </Grid>


                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>

                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box
                            component="img"
                            width='100%'
                            src="/images/business_free_ad.png"
                        />
                    </Grid>

                </Grid>

                <Paper elevation={1} sx={{ px: 5, py: 2, mt: 5, borderRadius: '10px' }}>
                    <Typography variant="h1" color="text.primary">
                        Parents Reviews
                    </Typography>
                    <Box>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                            <PersonIcon color='secondary' fontSize="small" />
                            <Typography variant="h4" color="text.dark" >
                                Name/Anonymous(My DayCare Review)
                            </Typography>
                        </Stack>
                        <Rating
                            name="simple-controlled"
                            value={5}
                            size="small"
                        />
                        <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                            <Box component="span" color="primary.main" >Read More</Box>
                        </Typography>
                        <Box sx={{ pl: 5, py: 3 }}>
                            <Typography variant="h4" color="text.dark">DayCare Representative Reply</Typography>
                            <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                                <Box component="span" color="primary.main" >Read More</Box>
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                            <PersonIcon color='secondary' fontSize="small" />
                            <Typography variant="h4" color="text.dark" >
                                Name/Anonymous(My DayCare Review)
                            </Typography>
                        </Stack>
                        <Rating
                            name="simple-controlled"
                            value={5}
                            size="small"
                        />
                        <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                            <Box component="span" color="primary.main" >Read More</Box>
                        </Typography>
                        <Box sx={{ pl: 5, py: 3 }}>
                            <Typography variant="h4" color="text.dark">DayCare Representative Reply</Typography>
                            <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                                <Box component="span" color="primary.main" >Read More</Box>
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                            <PersonIcon color='secondary' fontSize="small" />
                            <Typography variant="h4" color="text.dark" >
                                Name/Anonymous(My DayCare Review)
                            </Typography>
                        </Stack>
                        <Rating
                            name="simple-controlled"
                            value={5}
                            size="small"
                        />
                        <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                            <Box component="span" color="primary.main" >Read More</Box>
                        </Typography>
                        <Box sx={{ pl: 5, py: 3 }}>
                            <Typography variant="h4" color="text.dark">DayCare Representative Reply</Typography>
                            <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                                <Box component="span" color="primary.main" >Read More</Box>
                            </Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                            <PersonIcon color='secondary' fontSize="small" />
                            <Typography variant="h4" color="text.dark" >
                                Name/Anonymous(My DayCare Review)
                            </Typography>
                        </Stack>
                        <Rating
                            name="simple-controlled"
                            value={5}
                            size="small"
                        />
                        <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                            <Box component="span" color="primary.main" >Read More</Box>
                        </Typography>
                        <Box sx={{ pl: 5, py: 3 }}>
                            <Typography variant="h4" color="text.dark">DayCare Representative Reply</Typography>
                            <Typography variant="body1" color="text.dark">Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.felis. Integer euismod aliquet mau..........
                                <Box component="span" color="primary.main" >Read More</Box>
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ textAlign: "right" }}>
                        <Button
                            variant="contained"
                            size="large"
                        >
                            Read More
                        </Button>
                    </Box>

                </Paper>

                <Box sx={{ my: 3 }}>
                    <Typography variant="h1">
                        Our Community
                    </Typography>
                    <Grid container spacing={5}>
                        <Grid item xs={4} sx={{ textAlign: 'center' }}>
                            {<img src="/images/team1.png" alt="team" />}
                            <Typography variant="h3" color="blued.main">Sarah</Typography>
                            <Typography variant="h6" color="text.dark">House Wife</Typography>
                            <Typography variant="body2" color="text.dark">
                                Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet,
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'center', transform: 'scale(1.2)' }}>
                            {<img src="/images/team2.png" alt="team" />}
                            <Typography variant="h3" color="blued.main">Aman Khan</Typography>
                            <Typography variant="h6" color="text.dark">Teacher</Typography>
                            <Typography variant="body2" color="text.dark">
                                Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet,
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'center' }}>
                            {<img src="/images/team1.png" alt="team" />}
                            <Typography variant="h3" color="blued.main">Sarah</Typography>
                            <Typography variant="h6" color="text.dark">House Wife</Typography>
                            <Typography variant="body2" color="text.dark">
                                Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet,
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>

                <Divider sx={{ mt: 10, mb: 3 }} />



            </Container >

            <Footer />

        </>
    );
}

export default RecreationalBusinessFree;