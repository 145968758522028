
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
    Box, Grid, Container, Typography, Stack, Chip, Button,
    Card, CardActionArea, CardMedia, CardActions
} from "@mui/material";

import Aos from 'aos';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Search from "../../Search";
import Navbar from "../../Navbar/Navbar";
import Footer from '../../Footer/Footer';


const RecreationalComparison = () => {

    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
            offset: 120,
        })
    }, [])

    return (
        <Box>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(/images/child-bg-1.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>

            <Box sx={{ mb: 2 }}>
                {<img src='/images/ad_big.png' alt="Ads" />}
            </Box>
            <Box sx={{ px: 5 }}>
                <Grid container spacing={1}>
                    <Grid item xs sx={{ textAlign: 'center' }}>

                        <Typography variant="h3" color="text.dark">
                            Recreational Name
                        </Typography>

                        <Card
                            elevation={1}
                            sx={{
                                mt: 1, borderRadius: '10px',
                                background: 'rgba(255, 197, 20, 0.1)'
                            }} >

                            <CardActionArea component={Link} to={'/'}>
                                <CardMedia
                                    component="img"
                                    image={'/images/card.png'}
                                />
                                <Box sx={{ position: 'absolute', top: 10, right: 5 }}>
                                    <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                </Box>
                                <Box sx={{ background: '#fff', py: 2 }} >
                                    <Button
                                        variant="contained"
                                    >
                                        Contact for More Info
                                    </Button>

                                </Box>

                            </CardActionArea>

                            <CardActions sx={{ px: 1, pb: 2, textAlign: 'left' }}>
                                <Box>
                                    <Typography variant="h5" color="text.dark" >
                                        Program
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Daycare Centre, Licence Home Daycare, Home/Family Daycare, B&A School Care, Full Time, Part Time, Licence, Subsidies/Financial Aid, Summer Camp, Online
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Age
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Infants 0-18M, Toddlers 18M-30M, Preschool 30M-6Y, Kindergarten 4Y+
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Language
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        English,
                                        French,
                                        Spanish,
                                        Mandarin,
                                        Tagalog,
                                        Vietnamese,
                                        Urdu,
                                        Arabic,
                                        Russian,
                                        Sign Language
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Distance
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        1-3 Miles,
                                        6-9 Miles,
                                        3-6 Miles,
                                        10+ Miles
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Inclusion
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Special Diets, Neurological Diversity, Learning Diversity, Physical Diversity, Social and Emotional Diversity, Hearing Diversity, Visual Diversity
                                    </Typography>
                                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                                        <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />}>
                                            view more
                                        </Button>
                                    </Box>

                                </Box>
                            </CardActions>
                        </Card>

                    </Grid>

                    <Grid item xs sx={{ textAlign: 'center' }}>

                        <Typography variant="h3" color="text.dark">
                            Recreational Name
                        </Typography>

                        <Card
                            elevation={1}
                            sx={{
                                mt: 1, borderRadius: '10px',
                                background: 'rgba(4, 198, 245, 0.1)'
                            }} >

                            <CardActionArea component={Link} to={'/'}>
                                <CardMedia
                                    component="img"
                                    image={'/images/card.png'}
                                />
                                <Box sx={{ position: 'absolute', top: 10, right: 5 }}>
                                    <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                </Box>
                                <Box sx={{ background: '#fff', py: 2 }} >
                                    <Button
                                        variant="contained"
                                    >
                                        Contact for More Info
                                    </Button>

                                </Box>

                            </CardActionArea>

                            <CardActions sx={{ px: 1, pb: 2, textAlign: 'left' }}>
                                <Box>
                                    <Typography variant="h5" color="text.dark" >
                                        Program
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Daycare Centre, Licence Home Daycare, Home/Family Daycare, B&A School Care, Full Time, Part Time, Licence, Subsidies/Financial Aid, Summer Camp, Online
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Age
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Infants 0-18M, Toddlers 18M-30M, Preschool 30M-6Y, Kindergarten 4Y+
                                    </Typography>

                                    <Typography variant="h5" color="text.dark" >
                                        Language
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        English,
                                        French,
                                        Spanish,
                                        Mandarin,
                                        Tagalog,
                                        Vietnamese,
                                        Urdu,
                                        Arabic,
                                        Russian,
                                        Sign Language
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Distance
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        1-3 Miles,
                                        6-9 Miles,
                                        3-6 Miles,
                                        10+ Miles
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Inclusion
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Special Diets, Neurological Diversity, Learning Diversity, Physical Diversity, Social and Emotional Diversity, Hearing Diversity, Visual Diversity
                                    </Typography>
                                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                                        <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />}>
                                            view more
                                        </Button>
                                    </Box>
                                </Box>
                            </CardActions>
                        </Card>

                    </Grid>

                    <Grid item xs sx={{ textAlign: 'center' }}>

                        <Typography variant="h3" color="text.dark">
                            Recreational Name
                        </Typography>

                        <Card
                            elevation={1}
                            sx={{
                                mt: 1, borderRadius: '10px',
                                background: 'rgba(255, 197, 20, 0.1)'
                            }} >

                            <CardActionArea component={Link} to={'/'}>
                                <CardMedia
                                    component="img"
                                    image={'/images/card.png'}
                                />
                                <Box sx={{ position: 'absolute', top: 10, right: 5 }}>
                                    <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                </Box>
                                <Box sx={{ background: '#fff', py: 2 }} >
                                    <Button
                                        variant="contained"
                                    >
                                        Contact for More Info
                                    </Button>

                                </Box>

                            </CardActionArea>

                            <CardActions sx={{ px: 1, pb: 2, textAlign: 'left' }}>
                                <Box>
                                    <Typography variant="h5" color="text.dark" >
                                        Program
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Daycare Centre, Licence Home Daycare, Home/Family Daycare, B&A School Care, Full Time, Part Time, Licence, Subsidies/Financial Aid, Summer Camp, Online
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Age
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Infants 0-18M, Toddlers 18M-30M, Preschool 30M-6Y, Kindergarten 4Y+
                                    </Typography>

                                    <Typography variant="h5" color="text.dark" >
                                        Language
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        English,
                                        French,
                                        Spanish,
                                        Mandarin,
                                        Tagalog,
                                        Vietnamese,
                                        Urdu,
                                        Arabic,
                                        Russian,
                                        Sign Language
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Distance
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        1-3 Miles,
                                        6-9 Miles,
                                        3-6 Miles,
                                        10+ Miles
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Inclusion
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Special Diets, Neurological Diversity, Learning Diversity, Physical Diversity, Social and Emotional Diversity, Hearing Diversity, Visual Diversity
                                    </Typography>
                                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                                        <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />}>
                                            view more
                                        </Button>
                                    </Box>
                                </Box>
                            </CardActions>
                        </Card>

                    </Grid>


                    <Grid item xs sx={{ textAlign: 'center' }}>

                        <Typography variant="h3" color="text.dark">
                            Recreational Name
                        </Typography>

                        <Card
                            elevation={1}
                            sx={{
                                mt: 1, borderRadius: '10px',
                                background: 'rgba(4, 198, 245, 0.1)'
                            }} >

                            <CardActionArea component={Link} to={'/'}>
                                <CardMedia
                                    component="img"
                                    image={'/images/card.png'}
                                />
                                <Box sx={{ position: 'absolute', top: 10, right: 5 }}>
                                    <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                </Box>
                                <Box sx={{ background: '#fff', py: 2 }} >
                                    <Button
                                        variant="contained"
                                    >
                                        Contact for More Info
                                    </Button>

                                </Box>

                            </CardActionArea>

                            <CardActions sx={{ px: 1, pb: 2, textAlign: 'left' }}>
                                <Box>
                                    <Typography variant="h5" color="text.dark" >
                                        Program
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Daycare Centre, Licence Home Daycare, Home/Family Daycare, B&A School Care, Full Time, Part Time, Licence, Subsidies/Financial Aid, Summer Camp, Online
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Age
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Infants 0-18M, Toddlers 18M-30M, Preschool 30M-6Y, Kindergarten 4Y+
                                    </Typography>

                                    <Typography variant="h5" color="text.dark" >
                                        Language
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        English,
                                        French,
                                        Spanish,
                                        Mandarin,
                                        Tagalog,
                                        Vietnamese,
                                        Urdu,
                                        Arabic,
                                        Russian,
                                        Sign Language
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Distance
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        1-3 Miles,
                                        6-9 Miles,
                                        3-6 Miles,
                                        10+ Miles
                                    </Typography>
                                    <Typography variant="h5" color="text.dark" >
                                        Inclusion
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.dark">
                                        Special Diets, Neurological Diversity, Learning Diversity, Physical Diversity, Social and Emotional Diversity, Hearing Diversity, Visual Diversity
                                    </Typography>
                                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                                        <Button variant="contained" size="small" endIcon={<KeyboardArrowDownIcon />}>
                                            view more
                                        </Button>
                                    </Box>

                                </Box>
                            </CardActions>
                        </Card>

                    </Grid>

                </Grid>
                <Box sx={{ mt: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" size="large" >
                            Save My Search
                        </Button>
                        <Button variant="contained" size="large" >
                            Back to Results
                        </Button>
                    </Stack>

                </Box>
            </Box>

            <Box sx={{ px: 5, mt: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        {<img alt="" src="/images/ad.png" style={{ width: '100%' }} />}
                    </Grid>
                    <Grid item xs={4}>
                        {<img alt="" src="/images/ad.png" style={{ width: '100%' }} />}
                    </Grid>
                    <Grid item xs={4}>
                        {<img alt="" src="/images/ad.png" style={{ width: '100%' }} />}
                    </Grid>
                </Grid>
            </Box>

            <Footer />

        </Box>
    )
}

export default RecreationalComparison;