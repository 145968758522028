import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/config/ScrollToTop';


import Home from './Home';
import MainPage from './components/Home/MainPage';
import SearchResult from './components/SearchResult';
import Comparison from './components/Comparison';
import BusinessPage from './components/BusinessPage/index1';
import BusinessPremium from './components/BusinessPage/premium';
import BusinessMedium from './components/BusinessPage/medium';
import AboutCompany from './components/Pages/About';
import DaycareMembership from './components/Pages/RecereationalMembership';
import OurTeam from './components/Pages/OurTeam';
import RecreationalSearchResult from './components/Recreational/Search';
import BusinessFree from './components/BusinessPage/index1';
import RecreationalBusinessFree from './components/Recreational/BusinessPage';
import RecreationalBusinessMedium from './components/Recreational/BusinessPage/medium';
import RecreationalBusinessPremium from './components/Recreational/BusinessPage/premium';
import RecreationalComparison from './components/Recreational/Comparison';
import Articles from './components/Recreational/Parents';
import Article from './components/Recreational/Parents/Article';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<MainPage />} />
          </Route>
          <Route path="SearchResult" element={<SearchResult />} />
          <Route path="Comparison" element={<Comparison />} />
          <Route path="Business" element={<BusinessFree />} />
          <Route path='Business/Premium' element={<BusinessPremium />} />
          <Route path='Business/Medium' element={<BusinessMedium />} />
          <Route path='About' element={<AboutCompany />} />
          <Route path='Membership/Daycare' element={<DaycareMembership />} />
          <Route path='Membership/Recreational' element={<DaycareMembership />} />
          <Route path='Ourteam' element={<OurTeam />} />

          <Route path='Articles' element={<Articles />} />
          <Route path='Article' element={<Article />} />

          <Route path='Recreational/Search' element={<RecreationalSearchResult />} />
          <Route path="Recreational/Business" element={<RecreationalBusinessFree />} />
          <Route path='Recreational/Business/Premium' element={<RecreationalBusinessPremium />} />
          <Route path='Recreational/Business/Medium' element={<RecreationalBusinessMedium />} />
          <Route path="Recreational/Comparison" element={<RecreationalComparison />} />

        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
