import { Box, Grid, Container, Stack, Typography, Button, TextField } from '@mui/material';

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BusinessIcon from '@mui/icons-material/Business';

import LocationOnIcon from '@mui/icons-material/LocationOn';


const Footer = () => {

    return (

        <Box
            sx={{
                mt: 5, backgroundColor: '#005B71'
            }}
        >

            <Container maxWidth="lg">

                <Grid container sx={{ p: 4 }}>

                    <Grid item xs={12} sm={4} md={3} >

                        {<img src="/images/logo-white.png" width="135" />}
                        <Button
                            variant="contained"
                            sx={{ mt: 1, mb: 3 }}
                        >
                            Contact Info
                        </Button>

                        <Stack direction="row" sx={{ mb: 1 }}>
                            <PhoneInTalkIcon color="white" fontSize="small" sx={{ mr: 2 }} />
                            <Typography variant="body" color="text.white">(123)456-7890</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ mb: 1 }}>
                            <MailOutlineIcon color="white" fontSize="small" sx={{ mr: 2 }} />
                            <Typography variant="body" color="text.white">mail@xyz.com</Typography>
                        </Stack>
                        <Stack direction="row">
                            <LocationOnIcon color="white" fontSize="small" sx={{ mr: 2 }} />
                            <Typography variant="body" color="text.white">xyz location</Typography>
                        </Stack>

                    </Grid>
                    <Grid item xs={12} sm={4} md={5}>
                        <Typography variant="h3" color="text.white" sx={{ mt: 2, mb: 2 }}>Additional Links</Typography>

                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Write a review</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Recreational</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>For Parents</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Community Hub</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Login/Signup</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Recreational</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>For Parents</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Write a review</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Recreational</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>For Parents</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Community Hub</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Login/Signup</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Recreational</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>For Parents</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Write a review</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Recreational</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>For Parents</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Community Hub</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Login/Signup</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>Recreational</Typography>
                                <Typography variant="body1" color="text.white" sx={{ lineHeight:'2.5rem' }}>For Parents</Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }}>
                        <Typography variant="h3" color="text.white" sx={{ mb: 2 }}>
                            Signup for updates & emails
                        </Typography>
                        <TextField
                            name="email"
                            label="Email ID"
                            autoComplete="email"
                            fullWidth
                            size='small'
                            sx={{ mb: 2, maxWidth: '250px' }}
                        />
                        <Box>
                            <Box sx={{ textAlign: "center" }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Register
                                </Button>

                            </Box>

                        </Box>
                    </Grid>


                </Grid>

            </Container>
        </Box>
    )
}

export default Footer;