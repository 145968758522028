import {
    Container, Box, Grid, Paper, Typography, Stack
} from '@mui/material';


import Search from '../../Search/index.js';
import Navbar from '../../Navbar/Navbar.js';
import Footer from '../../Footer/Footer.js';

function Article() {

    return (
        <>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>


            <Container maxWidth='lg'>

                <Box>
                    <Typography variant="h3" color="text.dark" sx={{ pt: 3, pb: 2 }}>
                        Finding a DAYCARE
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <Paper elevation={3} sx={{ p: 3, borderRadius: '10px' }}>


                                <Grid container spacing={5}>
                                    <Grid item xs='auto'>
                                        <Box>
                                            {<img alt="" src="/images/article/article_author.png" />}
                                        </Box>

                                    </Grid>
                                    <Grid item xs>
                                        <Stack direction='row' spacing={3} alignItems='baseline' sx={{ mb: 3 }}>
                                            <Typography variant='h2' color='text.dark'>
                                                Jane Doe
                                            </Typography>
                                            <Typography variant='h6' color='text.gray'>
                                                16/6/2024
                                            </Typography>
                                        </Stack>
                                        <Typography variant='h2'>
                                            Home DayCare or a Center Home DayCare or a Center Home.
                                        </Typography>
                                        <Stack direction='row' spacing={3} justifyContent='end'>
                                            {<img alt="" src="/images/article/linkedin_color.png" />}
                                            {<img alt="" src="/images/article/twitter_color.png" />}
                                            {<img alt="" src="/images/article/fb_color.png" />}
                                            {<img alt="" src="/images/article/insta_color.png" />}
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box sx={{ mb: 2 }}>
                                            {<img alt="" src="/images/article/article_header.png" />}
                                        </Box>
                                        <Typography color='text.dark' sx={{ fontSize: '1.8rem', lineHeight: 1.5, mb: 5 }}>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat id velit id rutrum. Etiam lacinia ligula vitae odio convallis ornare. In viverra dignissim arcu, auctor gravida velit ultricies non. Nam venenatis nunc eu sem porta, quis porttitor metus ornare. Aenean et vulputate diam, sit amet malesuada magna. Aliquam quis pellentesque massa, in pretium felis. Donec vestibulum nulla et sapien convallis molestie. Ut ornare, sapien non fringilla efficitur, sapien tortor sollicitudin mi, at lobortis ex leo quis mi. Fusce faucibus neque finibus ipsum pellentesque ultricies. In feugiat viverra fermentum. Suspendisse fermentum elit vel odio viverra laoreet. Vestibulum finibus tortor ligula, sit amet scelerisque turpis tincidunt in. Proin ac venenatis ex.
                                        </Typography>
                                        <Typography color='text.dark' sx={{ fontSize: '1.8rem', lineHeight: 1.5, mb: 5 }}>

                                            Sed diam augue, tincidunt vel eros vitae, lobortis viverra lectus. Duis leo sem, consectetur sit amet tellus a, fringilla posuere augue. Aenean mollis dapibus suscipit. Proin ornare ante sed fringilla lacinia. Proin maximus dapibus orci a consectetur. Ut pretium neque a velit ornare porta. In vel sem ac est convallis mollis. Duis varius lacus eu sagittis pellentesque. Duis maximus ex non arcu scelerisque, vitae suscipit nulla eleifend. Vivamus gravida commodo magna et fermentum. Proin consectetur felis massa. Donec id ante eu lorem pulvinar ultrices eget vitae sapien. Nulla facilisi. Etiam elit turpis, facilisis eget mauris a, sagittis aliquam magna. Nullam cursus nec arcu sit amet viverra.
                                        </Typography>
                                        <Typography color='text.dark' sx={{ fontSize: '1.8rem', lineHeight: 1.5, mb: 5 }}>
                                            Nulla porttitor dui sed mauris ultrices, non egestas augue volutpat. Nulla porttitor dui sed mauris ultrices, non egestas augue volutpat. Nulla porttitor dui sed mauris ultrices, non egestas augue augu volutpat.
                                        </Typography>
                                        <Typography color='text.dark' sx={{ fontSize: '1.8rem', lineHeight: 1.5, mb: 5 }}>
                                            Sed diam augue, tincidunt vel eros vitae, lobortis viverra lectus. Duis leo sem, consectetur sit amet tellus a, fringilla posuere augue. Aenean mollis dapibus suscipit. Proin ornare ante sed fringilla lacinia. Proin maximus dapibus orci a consectetur. Ut pretium neque a velit ornare porta. In vel sem ac est convallis mollis. Duis varius lacus eu sagittis pellentesque. Duis maximus ex non arcu scelerisque, vitae suscipit nulla eleifend. Vivamus gravida commodo magna et fermentum. Proin consectetur felis massa. Donec id ante eu lorem pulvinar ultrices eget vitae sapien. Nulla facilisi. Etiam elit turpis, facilisis eget mauris a, sagittis aliquam magna. Nullam cursus nec arcu sit amet viverra.
                                        </Typography>
                                        <Typography color='text.dark' sx={{ fontSize: '1.8rem', lineHeight: 1.5, mb: 5 }}>
                                            Nulla porttitor dui sed mauris ultrices, non egestas augue volutpat. Nulla porttitor dui sed mauris ultrices, non egestas augue volutpat. Nulla porttitor dui sed mauris ultrices, non egestas augue augu volutpat.
                                        </Typography>

                                        <Stack direction='row' justifyContent='space-between'>
                                            <Stack direction='row' spacing={3} justifyContent='end'>
                                                {<img alt="" src="/images/article/like.png" />}
                                                {<img alt="" src="/images/article/dislike.png" />}
                                            </Stack>
                                            <Stack direction='row' spacing={3} justifyContent='end'>
                                                {<img alt="" src="/images/article/linkedin_color.png" />}
                                                {<img alt="" src="/images/article/twitter_color.png" />}
                                                {<img alt="" src="/images/article/fb_color.png" />}
                                                {<img alt="" src="/images/article/insta_color.png" />}
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Paper>

                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant='h3' color='text.dark' sx={{ mb: 2 }}>
                                Most read articles
                            </Typography>

                            <Box sx={{ backgroundColor: '#FFF9E7', mb: 1 }}>
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    {<img alt="" src="/images/article/featured_article1.png" />}
                                    <Typography variant='h4' color='text.dark'>
                                        Home Daycare for a Centre
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box sx={{ backgroundColor: '#E6F9FE', mb: 1 }}>
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    {<img alt="" src="/images/article/featured_article2.png" />}
                                    <Typography variant='h4' color='text.dark'>
                                        Food Portions
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box sx={{ backgroundColor: '#FFF9E7', mb: 1 }}>
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    {<img alt="" src="/images/article/featured_article3.png" />}
                                    <Typography variant='h4' color='text.dark'>
                                        List of fun activities for the family
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box sx={{ backgroundColor: '#FFEFED', mb: 1 }}>
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    {<img alt="" src="/images/article/featured_article4.png" />}
                                    <Typography variant='h4' color='text.dark'>
                                        Licensed vs unlicensed pros and cons
                                    </Typography>
                                </Stack>
                            </Box>
                            <Box sx={{ backgroundColor: '#F0F9EB', mb: 1 }}>
                                <Stack direction='row' spacing={2} alignItems='center'>
                                    {<img alt="" src="/images/article/featured_article5.png" />}
                                    <Typography variant='h4' color='text.dark'>
                                        Healthy food
                                    </Typography>
                                </Stack>
                            </Box>

                            <Box sx={{ mb: 2 }}>
                                {<img alt="" src="/images/ad.png" width='100%' />}
                            </Box>
                            <Box>
                                {<img alt="" src="/images/ad.png" width='100%' />}
                            </Box>

                        </Grid>
                    </Grid>

                </Box >

            </Container >

            <Footer />

        </>
    );
}

export default Article;