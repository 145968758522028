import {
    Box, Button, Checkbox, FormControlLabel, Grid, Link, Modal,
    Stack, TextField, Typography
} from "@mui/material"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #091e44',
    boxShadow: 24,
    textAlign: 'center',
    px: 10, py: 2
};


const WaitinglistModal = (props) => {

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box>

                    <Typography variant="h2" sx={{ textAlign: 'left', mb: 2 }}>
                        Join a Waiting List
                    </Typography>
                    <Box component="form" sx={{ textAlign: 'left' }} >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    name="firstname"
                                    label="First Name"
                                    autoComplete="firstname"
                                    size="small"
                                    fullWidth
                                // value={formik.values.firstname}
                                // onChange={formik.handleChange}
                                // error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                // helperText={formik.touched.firstname && formik.errors.firstname}
                                />
                            </Grid>



                            <Grid item xs={12}>
                                <Typography variant="h3" color="text.secondary">
                                    Personal Information
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="firstname"
                                    label="First Name"
                                    autoComplete="firstname"
                                    size="small"
                                    fullWidth
                                // value={formik.values.firstname}
                                // onChange={formik.handleChange}
                                // error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                // helperText={formik.touched.firstname && formik.errors.firstname}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    name="lastname"
                                    label="Last Name"
                                    autoComplete="lastname"
                                    size="small"
                                    fullWidth
                                // value={formik.values.lastname}
                                // onChange={formik.handleChange}
                                // error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                // helperText={formik.touched.lastname && formik.errors.lastname}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="phoneNumber"
                                    label="Contact Number"
                                    autoComplete="phone"
                                    size="small"
                                    fullWidth
                                // value={formik.values.phoneNumber}
                                // onChange={formik.handleChange}
                                // error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                // helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                />

                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="email"
                                    label="Email ID"
                                    autoComplete="email"
                                    size="small"
                                    fullWidth
                                // value={formik.values.email}
                                // onChange={formik.handleChange}
                                // error={formik.touched.email && Boolean(formik.errors.email)}
                                // helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="city"
                                    label="City"
                                    autoComplete="city"
                                    size="small"
                                    fullWidth
                                // value={formik.values.city}
                                // onChange={formik.handleChange}
                                // error={formik.touched.city && Boolean(formik.errors.city)}
                                // helperText={formik.touched.city && formik.errors.city}
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="state"
                                    label="State/Province"
                                    autoComplete="state"
                                    size="small"
                                    fullWidth
                                // value={formik.values.state}
                                // onChange={formik.handleChange}
                                // error={formik.touched.state && Boolean(formik.errors.state)}
                                // helperText={formik.touched.state && formik.errors.state}
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="country"
                                    label="Country"
                                    autoComplete="country"
                                    size="small"
                                    fullWidth
                                // value={formik.values.country}
                                // onChange={formik.handleChange}
                                // error={formik.touched.country && Boolean(formik.errors.country)}
                                // helperText={formik.touched.country && formik.errors.country}
                                />

                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="h3" color="text.secondary">
                                    Child Information
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="childName"
                                    label="Child Name"
                                    size="small"
                                    fullWidth
                                // value={formik.values.childName}
                                // onChange={formik.handleChange}
                                // error={formik.touched.childName && Boolean(formik.errors.childName)}
                                // helperText={formik.touched.childName && formik.errors.childName}
                                />

                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <TextField
                                    name="childAge"
                                    label="Child Age"
                                    size="small"
                                    fullWidth
                                // value={formik.values.childAge}
                                // onChange={formik.handleChange}
                                // error={formik.touched.childAge && Boolean(formik.errors.childAge)}
                                // helperText={formik.touched.childAge && formik.errors.childAge}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <Link>Add another child</Link>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                    <FormControlLabel
                                        label="Add me to your mailing list"
                                        control={
                                            <Checkbox color="secondary"
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label="Agree to terms and conditions"
                                        control={
                                            <Checkbox color="secondary"
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>




                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    type="submit"
                                >
                                    Submit
                                </Button>

                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default WaitinglistModal