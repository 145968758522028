import { useState } from 'react';

import { Box, Menu, MenuItem, ListItemIcon, Stack, Button, SvgIcon, Typography } from "@mui/material";

import { ReactComponent as StarIcon } from '../../icons/1_1.svg';


const ProgramMenu = [
    { title: 'Daycare Center', icon: '1_1' },
    { title: 'Licence Home Daycare', icon: '1_1' },
    { title: 'Home/Family Daycare', icon: '1_1' },
    { title: 'B&A School Care', icon: '1_1' },
    { title: 'Full Time', icon: '1_1' },
    { title: 'Part Time', icon: '1_1' },
    { title: 'Licence', icon: '1_1' },
    { title: 'Subsidies/Financial Aid', icon: '1_1' },
    { title: 'Summer Camp', icon: '1_1' },
    { title: 'Online', icon: '1_1' },
];

const AgeMenu = [
    { title: 'Infants 0-18M', icon: '1_1' },
    { title: 'Toddlers 18M-30M', icon: '1_1' },
    { title: 'Preschool 30M-6Y', icon: '1_1' },
    { title: 'Kindergarten 4Y+', icon: '1_1' },
]

const PhilosophyMenu = [
    { title: 'Academic Based', icon: '1_1' },
    { title: 'Play-Based', icon: '1_1' },
    { title: 'Montessori', icon: '1_1' },
    { title: 'Waldorf', icon: '1_1' },
    { title: 'Reggio Emilia', icon: '1_1' },
    { title: 'Community-Based', icon: '1_1' },
    { title: 'Faith-Based', icon: '1_1' },
    { title: 'HighScope', icon: '1_1' },
    { title: 'Mother Goose', icon: '1_1' },
    { title: 'Bank Street', icon: '1_1' },
    { title: 'Forest School', icon: '1_1' },

]

const MenuMenu = [
    { title: 'Fresh Cooked', icon: '1_1' },
    { title: 'Paleo', icon: '1_1' },
    { title: 'Vegan', icon: '1_1' },
    { title: 'Vegetarian', icon: '1_1' },
    { title: 'Organic', icon: '1_1' },
    { title: 'Kosher', icon: '1_1' },
    { title: 'Halal', icon: '1_1' },
]

const ExtrasMenu = [
    { title: 'Yoga', icon: '1_1' },
    { title: 'Music', icon: '1_1' },
    { title: 'Cooking', icon: '1_1' },
    { title: 'Languages', icon: '1_1' },
    { title: 'Vosual Arts', icon: '1_1' },
    { title: 'STEM', icon: '1_1' },
    { title: 'Math', icon: '1_1' },
    { title: 'Art', icon: '1_1' },
    { title: 'Drama', icon: '1_1' },
]

const LanguageMenu = [
    { title: 'English', icon: '1_1' },
    { title: 'French', icon: '1_1' },
    { title: 'Spanish', icon: '1_1' },
    { title: 'Mandarin', icon: '1_1' },
    { title: 'Tagalog', icon: '1_1' },
    { title: 'Vietnamese', icon: '1_1' },
    { title: 'Urdu', icon: '1_1' },
    { title: 'Arabic', icon: '1_1' },
    { title: 'Russian', icon: '1_1' },
    { title: 'Sign Language', icon: '1_1' },
]
const DistanceMenu = [
    { title: '1-3 Miles', icon: '1_1' },
    { title: '3-6 Miles', icon: '1_1' },
    { title: '6-9 Miles', icon: '1_1' },
    { title: '10+ Miles', icon: '1_1' },
]

const InclusionMenu = [
    { title: 'Special Diets', icon: '1_1' },
    { title: 'Neurological Diversity', icon: '1_1' },
    { title: 'Physical Diversity', icon: '1_1' },
    { title: 'Social and Emotional Diversity', icon: '1_1' },
    { title: 'Hearing Diversity', icon: '1_1' },
    { title: 'Visual Diversity', icon: '1_1' },
]

const AdvancedSearchBlue = () => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [anchorEl5, setAnchorEl5] = useState(null);
    const [anchorEl6, setAnchorEl6] = useState(null);
    const [anchorEl7, setAnchorEl7] = useState(null);
    const open = Boolean(anchorEl);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);
    const open5 = Boolean(anchorEl5);
    const open6 = Boolean(anchorEl6);
    const open7 = Boolean(anchorEl7);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClick4 = (event) => {
        setAnchorEl4(event.currentTarget);
    };
    const handleClick5 = (event) => {
        setAnchorEl5(event.currentTarget);
    };
    const handleClick6 = (event) => {
        setAnchorEl6(event.currentTarget);
    };
    const handleClick7 = (event) => {
        setAnchorEl7(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl1(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
        setAnchorEl4(null);
        setAnchorEl5(null);
        setAnchorEl6(null);
        setAnchorEl7(null);
    };

    return (

        <Box sx={{ px: 3, mt: 3 }}>
            <Typography variant="h3">
                Advance Search
            </Typography>
            <Stack direction='row' spacing={4} sx={{ mt: 1, mb: 3, textAlign: 'center', alignItems: 'baseline' }}>
                <Box onClick={handleClick}>
                    {<img src="/images/icons/1.png" />}
                    <Typography variant='h5' color="text.dark">
                        Program
                    </Typography>
                </Box>

                <Menu
                    id="program-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {ProgramMenu.map((program, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {program.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>

                <Box onClick={handleClick1}>
                    {<img src="/images/icons/2.png" />}
                    <Typography variant='h5' color="text.dark">
                        Age
                    </Typography>
                </Box>

                <Menu
                    id="age-menu"
                    anchorEl={anchorEl1}
                    open={open1}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {AgeMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>

                <Box onClick={handleClick2}>
                    {<img src="/images/icons/3.png" />}
                    <Typography variant='h5' color="text.dark">
                        Philosophy
                    </Typography>
                </Box>

                <Menu
                    id="philosophy-menu"
                    anchorEl={anchorEl2}
                    open={open2}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {PhilosophyMenu.map((philosophy, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {philosophy.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>

                <Box onClick={handleClick3}>
                    {<img src="/images/icons/4.png" />}
                    <Typography variant='h5' color="text.dark">
                        Menu
                    </Typography>
                </Box>
                <Menu
                    id="menu-menu"
                    anchorEl={anchorEl3}
                    open={open3}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {MenuMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box></MenuItem>
                    ))}
                </Menu>
                <Box onClick={handleClick4}>
                    {<img src="/images/icons/5.png" />}
                    <Typography variant='h5' color="text.dark">
                        Extras
                    </Typography>
                </Box>
                <Menu
                    id="extras-menu"
                    anchorEl={anchorEl4}
                    open={open4}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {ExtrasMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box></MenuItem>
                    ))}
                </Menu>
                <Box onClick={handleClick5}>
                    {<img src="/images/icons/6.png" />}
                    <Typography variant='h5' color="text.dark">
                        Language
                    </Typography>
                </Box>
                <Menu
                    id="language-menu"
                    anchorEl={anchorEl5}
                    open={open5}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {LanguageMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box></MenuItem>
                    ))}
                </Menu>

                <Box onClick={handleClick6}>
                    {<img src="/images/icons/7.png" />}
                    <Typography variant='h5' color="text.dark">
                        Distance
                    </Typography>
                </Box>
                <Menu
                    id="distance-menu"
                    anchorEl={anchorEl6}
                    open={open6}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {DistanceMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box></MenuItem>
                    ))}
                </Menu>
                <Box onClick={handleClick7}>
                    {<img src="/images/icons/8.png" />}
                    <Typography variant='h5' color="text.dark">
                        Inclusion
                    </Typography>
                </Box>
                <Menu
                    id="inclusion-menu"
                    anchorEl={anchorEl7}
                    open={open7}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {InclusionMenu.map((age, index) => (
                        <MenuItem key={index}
                            sx={{
                                pr: 0, py: 0,
                                '&, & .MuiListItemIcon-root': {
                                    color: 'peach.main',
                                },
                                '&:hover': {
                                    '&, & .MuiListItemIcon-root': {
                                        color: 'text.white',
                                    },
                                    bgcolor: 'peach.main'
                                },
                            }}
                        >
                            <ListItemIcon>
                                <SvgIcon component={StarIcon} />
                            </ListItemIcon>
                            <Box sx={{ width: '100%', borderBottom: '1px solid #FFB399', pr: 5 }} >
                                <Typography variant="h5"
                                    sx={{ py: 1, color: 'text.dark', '&:hover': { color: 'text.white', } }}
                                >
                                    {age.title}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Menu>
            </Stack>
            <Box sx={{ textAlign: 'center' }}>
                <Button
                    variant="contained"
                    size="large"
                    type="submit"
                >
                    Search
                </Button>

            </Box>

        </Box>
    )
}

export default AdvancedSearchBlue;