
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import {
    Box, Grid, Container, Checkbox, IconButton, Typography, Paper, Stack, Chip, Button,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    Card, CardActionArea, CardMedia, CardActions, Divider, Rating
} from "@mui/material";
import { styled } from '@mui/material/styles';

import Aos from 'aos';

import CommentIcon from '@mui/icons-material/Comment';

import Search from "../Search";
import Navbar from "../Navbar/Navbar";
import Footer from '../Footer/Footer';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ffffff',
    },
    '& .MuiRating-iconHover': {
        color: '#ffffff',
    },
});

const program = [
    'Daycare Centre',
    'Licence Home Daycare',
    'Home/Family Daycare',
    'B&A School Care',
    'Full Time',
    'Part Time',
    'Licence',
    'Subsidies/Financial Aid',
    'Summer Camp',
    'Online'
]

const age = [
    'Infants 0-18M',
    'Toddlers 18M-30M',
    'Preschool 30M-6Y',
    'Kindergarten 4Y+'
]

const philosophy = [
    'Academic Based',
    'Play-Based',
    'Montessori',
    'Waldorf',
    'Reggio Emilia',
    'Community-Based',
    'Faith-Based',
    'HighScope',
    'Mother Goose',
    'Bank Street',
    'Forest School'
]

const menu = [
    'Fresh Cooked',
    'Paleo',
    'Vegan',
    'Organic',
    'Vegetarian',
    'Kosher',
    'Halal'
]

const extras = [
    'Yoga',
    'Music',
    'Cooking',
    'Languages',
    'Visual Arts',
    'STEM',
    'Math',
    'Art',
    'Drama'
]

const language = [
    'English',
    'French',
    'Spanish',
    'Mandarm',
    'Tagalog',
    'Vietnamese',
    'Urdu',
    'Arabic',
    'Russian',
    'Sign Language'
]

const distance = [
    '6-9 Miles',
    '3-6 Miles',
    '10+ Miles'
]

const inclusion = [
    'Special Diets',
    'Neurological Diversity',
    'Learning Diversity',
    'Physical Diversity',
    'Social and Emotional Diversity',
    'Hearing Diversity',
    'Visual Diversity'
]

const SearchResult = () => {

    useEffect(() => {
        Aos.init({
            duration: 1000,
            once: true,
            offset: 120,
        })
    }, [])

    return (
        <Box>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>
            <Box sx={{ px: 5, pt: 8 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Paper elevation={1} sx={{ px: 1 }}>
                            <Typography variant="h4" color="text.dark">
                                Program
                            </Typography>

                            <List>
                                {program.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Age
                            </Typography>

                            <List>
                                {age.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Philosophy
                            </Typography>

                            <List>
                                {philosophy.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Menu
                            </Typography>

                            <List>
                                {menu.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Extras
                            </Typography>

                            <List>
                                {extras.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Language
                            </Typography>

                            <List>
                                {language.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Distance
                            </Typography>

                            <List>
                                {distance.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Inclusion
                            </Typography>

                            <List>
                                {inclusion.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                        </Paper>

                        <Box sx={{ my: 2 }}>
                            {<img src="/images/ad.png" style={{ width: '100%' }} />}
                        </Box>
                        <Box>
                            {<img src="/images/ad.png" style={{ width: '100%' }} />}
                        </Box>

                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="caption" color="text.dark">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam euismod convallis. Suspendisse ac urna sed velit hendrerit gravida. Curabitur aliquam id dui vitae pellentesque.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra.
                        </Typography>

                        <Typography variant="h3" color="text.dark">
                            Daycares based on your search
                        </Typography>

                        <Grid container direction="row" spacing={3} sx={{ mt: 0, mb: 3 }}>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>



                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>


                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>


                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                        </Grid>

                        <Typography variant="h3" color="text.dark">
                            See More Results
                        </Typography>

                        <Box sx={{
                            mt: 2,

                            backgroundImage: 'url(./images/ads-banner-bg.png)',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',

                        }}
                        >
                            <Box sx={{
                                height: '134px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {<img src='/images/ad_banner_h.png' alt="Ads" />}

                            </Box>


                        </Box>

                        <Typography variant="h3" color="text.dark">
                            Here are more results that might interested you
                        </Typography>

                        <Grid container direction="row" spacing={3} sx={{ mt: 0, mb: 3 }}>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>



                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>


                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>

                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4} data-aos="fade-right">
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'./images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                >
                                                    Indoor play area
                                                </Typography>
                                                <Typography variant="body2" color="text.white">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>

                                                <Stack direction='row' sx={{ textAlign: 'center', alignItems: 'baseline' }}>

                                                    <Box>{<img src="/images/icons/1.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/2.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/3.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/4.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/5.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/6.png" style={{ width: '100%', opacity: '0.5' }} />} </Box>
                                                    <Box>{<img src="/images/icons/7.png" style={{ width: '100%' }} />} </Box>
                                                    <Box>{<img src="/images/icons/8.png" style={{ width: '100%' }} />} </Box>
                                                </Stack>
                                            </Grid>


                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    My Daycare
                                                </Typography>
                                                <StyledRating name="read-only" value={4} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body2" color="text.white">
                                                    Google
                                                </Typography>
                                                <StyledRating name="read-only" value={3} size="small" readOnly />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            2-5
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Years Old
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            22
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Class Size
                                                        </Typography>
                                                    </Grid>
                                                    <Divider orientation="vertical" sx={{ borderColor: '#fff' }} flexItem />
                                                    <Grid item xs>
                                                        <Typography variant="body1" color="text.white">
                                                            15
                                                        </Typography>
                                                        <Typography variant="body1" color="text.white">
                                                            Items
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Grid container>
                                                    <Grid item xs={4}>
                                                        <Chip label="See More" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Compare" color="orange" size="small" />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Chip label="Quick Connect" color="orange" size="small" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                        </Grid>

                        <Button
                            variant="contained"
                            size="large"
                        >
                            See More Results
                        </Button>

                    </Grid>
                </Grid>
            </Box>

            <Footer />

        </Box>
    )
}

export default SearchResult;