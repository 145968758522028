const Typography = (fontFamily) => ({
    htmlFontSize: 10,
    fontFamily,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 'bold',
        fontSize: '4rem',
        lineHeight: 1.6,
        textTransform: 'capitalize'
    },
    h2: {
        fontWeight: 600,
        fontSize: '3.2rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h3: {
        fontWeight: 600,
        fontSize: '2.4rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h4: {
        fontWeight: 600,
        fontSize: '2rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h5: {
        fontWeight: 800,
        fontSize: '1.6rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    h6: {
        fontWeight: 600,
        fontSize: '1.4rem',
        lineHeight: 1.4,
        textTransform: 'capitalize'
    },
    caption: {
        fontWeight: 400,
        fontSize: '1.5rem'
    },
    body1: {
        fontSize: '1.4rem',
        lineHeight: 1.3
    },
    body2: {
        fontSize: '1.6rem',
        lineHeight: 1.5
    },
    subtitle1: {
        fontSize: '1.1rem',
        fontWeight: 600,
        lineHeight: 1.57
    },
    subtitle2: {
        fontSize: '1.8rem',
        lineHeight: 1.3,
    },
    overline: {
        lineHeight: 1.66
    },
    button: {
        textTransform: 'capitalize'
    }
});

export default Typography;