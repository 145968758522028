import { Link } from "react-router-dom";
import {
    Box, Grid, Container, Checkbox, Typography, Paper, Stack, Chip, Button,
    List, ListItem, ListItemIcon, ListItemText,
    Card, CardActionArea, CardMedia, CardActions, Divider, Rating
} from "@mui/material";
import { styled } from '@mui/material/styles';


import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';

import Search from '../../Search';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ffffff',
    },
    '& .MuiRating-iconHover': {
        color: '#ffffff',
    },
});

const sports = [
    'Sports',
    'Soccer',
    'Tennis',
    'Swimming',
    'Gymnastics',
    'Football',
    'Baseball',
    'Basketball',
    'Volleyball',
    'Hockey',
    'Track and field',
    'Fencing',
    'Trampoline',
    'Ice Skating',
    'Yoga',
];
const arts = [
    'Painting &  Drawing',
    'Sewing',
    'Pottery',
    'Origami',
    'Woodworking',
    'Jewellery making',
    'Sculpting',
    'Photography',
    'Fashion design',
    'Creative writing',
    'Anime/Manga',
    'Creative Writing & Journalism',
    'Cartooning',
    'Ceramics',
];
const music = [
    'Piano',
    'Guitar',
    'Violin',
    'Singing',
    'Drums',
    'Flute',
    'Trumpet',
    'Saxophone',
    'Ukulele',
    'Music composition',
    'Glee Club'
];
const dance = [
    'Ballet',
    'Hip hop',
    'Jazz',
    'Tap dancing',
    'Contemporary',
    'Salsa and Latin',
    'Break dancing',
    'Ballroom dancing',
    'Irish dancing',
    'Bollywood dancing'
];
const outdoor = [
    'Hiking',
    'Camping',
    'Cycling',
    'Rollerblading',
    'Skateboarding',
    'Fishing',
    'Birdwatching',
    'Horseback Riding / Equestrian',
    'Mountain Biking',
    'Skateboarding',
    'Archery',
    'Rock Climbing',
    'Golf',
    'Paintball',
    'Parkour'
];

const stem = [
    'Astronomy',
    'Chemistry experiments',
    'Paleontology / Archaeology',
    'Coding & Programming',
    'Robotics',
    'Environmental and Weather studies',
    'Geology',
    'Anatomy and Biology',
    'Web Development',
    'Video Game Development',
    'Visual Art Design',
    'Botany',
    'Financial Literacy',
    'Aviation',
    'Engineering'
];

const drama = [

    'Acting',
    'Improvisation',
    'Scriptwriting',
    'Stage design',
    'Costume design',
    'Puppetry',
    'Directing',
    'Set construction',
    'Makeup artistry',
    'Lighting design',
    'Film making'
];

const mindGames = [
    'Chess',
    'Strategy board games',
    'Riddles and brain teasers',
    'Card games(e.g., Poker, Bridge)',
    'Word games(e.g., Scrabble, Boggle)',
    'Puzzles',
    'D & D',
    'LEGO'
];
const martialArts = [
    'Martial Arts',
    'Karate',
    'Judo',
    'Kung Fu',
    'Aikido',
    'Capoeira',
    'Krav Maga',
    'Kickboxing',
    'Muay Thai',
    'Brazilian Jiu Jitsu',
    'Taekwondo',
    'Boxing',
    'Tai Chi'
];
const leadership = [
    'Girls Empowerment',
    'CIT / LIT Program',
    'Leadership Training',
    'Entrepreneurship',
    'Social Justice',
    'Debate',
    'Public Speaking',
    'Growth Mindset',
    'Scouts'
];
const education = [
    'Math',
    'Credit Courses',
    'English',
    'Languages',
    'Tutoring',
    'Nature / Environment',
    'Reading',
    'Cooking',
    'Gardening',
    'Baking / Decorating'
]

const RecreationalSearchResult = () => {


    return (
        <Box>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>
            <Box sx={{ px: 5, pt: 8 }}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Paper elevation={1} sx={{ px: 1 }}>
                            <Typography variant="h4" color="text.dark">
                                Sports
                            </Typography>

                            <List>
                                {sports.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Arts and Design
                            </Typography>

                            <List>
                                {arts.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Music
                            </Typography>

                            <List>
                                {music.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Dance
                            </Typography>

                            <List>
                                {dance.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Outdoor & Extreme Sport:
                            </Typography>

                            <List>
                                {outdoor.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Stem
                            </Typography>

                            <List>
                                {stem.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Drama and Theatre:
                            </Typography>

                            <List>
                                {drama.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Mind Games
                            </Typography>

                            <List>
                                {mindGames.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Martial Arts
                            </Typography>

                            <List>
                                {martialArts.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Leadership
                            </Typography>

                            <List>
                                {leadership.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <Typography variant="h4" color="text.dark">
                                Education
                            </Typography>

                            <List>
                                {education.map((value) => {
                                    return (
                                        <ListItem disablePadding key={value}>
                                            <ListItemIcon sx={{ minWidth: 0 }}>
                                                <Checkbox size='small' sx={{ p: 0, color: 'text.dark' }} />
                                            </ListItemIcon>
                                            <ListItemText primary={value} sx={{ color: 'text.dark' }} />
                                        </ListItem>
                                    )
                                })}
                            </List>

                        </Paper>

                    </Grid>

                    <Grid item xs={10}>
                        <Typography variant="caption" color="text.dark">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra. Nulla suscipit mauris ac neque dignissim egestas. In sit amet dolor neque. Sed in odio egestas quam euismod convallis. Suspendisse ac urna sed velit hendrerit gravida. Curabitur aliquam id dui vitae pellentesque.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin pulvinar gravida velit, nec vehicula velit eleifend non. Ut consequat tempus viverra.
                        </Typography>

                        <Typography variant="h4" color="text.dark">
                            Recreational centers on your search
                        </Typography>

                        <Grid container direction="row" spacing={3} sx={{ mt: 0, mb: 3 }}>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>



                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                        </Grid>

                        <Typography variant="h4" color="text.dark">
                            See More Results
                        </Typography>

                        <Box sx={{
                            mt: 2,

                            backgroundImage: 'url(/images/ads-banner-bg.png)',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',

                        }}
                        >
                            <Box sx={{
                                height: '134px',
                                background: 'rgba(0, 0, 0, 0.6)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {<img src='/images/ad_banner_h.png' alt="Ads" />}

                            </Box>


                        </Box>

                        <Typography variant="h4" color="text.dark">
                            Here are more results that might interested you
                        </Typography>

                        <Grid container direction="row" spacing={3} sx={{ mt: 0, mb: 3 }}>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>



                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FF5C49',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#6BC53C',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                            <Grid item xs={12} md={4}>
                                <Card
                                    elevation={2}
                                    sx={{
                                        height: '100%', transition: '.3s',
                                        ":hover": { transform: 'translateY(-2rem)' },
                                        background: '#FFB200',
                                        borderRadius: '10px',
                                        textAlign: 'center'
                                    }} >

                                    <CardActionArea component={Link} to={'/'}>
                                        <CardMedia
                                            component="img"
                                            image={'/images/card.png'}
                                        />
                                        <Box sx={{ position: 'absolute', top: 20, left: 15 }}>
                                            <Chip
                                                icon={<img alt="" src="/images/white_pet.png" />}
                                                color="red" sx={{ border: '1px solid #fff' }}
                                            />
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: 20, right: 15 }}>
                                            <Chip label="Add to my list" color="red" sx={{ border: '1px solid #fff' }} />
                                        </Box>


                                    </CardActionArea>
                                    <CardActions sx={{ px: 3, py: 2 }}>

                                        <Grid container direction="row" columnSpacing={2} rowSpacing={1}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="h3"
                                                    color="text.white"
                                                    sx={{ textAlign: 'center' }}
                                                >
                                                    Vendor Name
                                                </Typography>

                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs>
                                                        <Typography
                                                            color="text.white"
                                                            sx={{
                                                                textAlign: 'justify', mb: 2,
                                                                fontSize: '1rem',
                                                                lineHeight: 1.2
                                                            }}
                                                        >
                                                            Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simply Lorem Ipsum is simplyLorem Ipsum is simply
                                                        </Typography>
                                                        <Stack direction='row' justifyContent='space-between' sx={{ mb: 2 }}>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                2-5 years old
                                                            </Typography>
                                                            <Typography color="text.white" sx={{ fontSize: '1.2rem' }}>
                                                                Town Name
                                                            </Typography>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />
                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                My Daycare
                                                            </Typography>
                                                            <StyledRating name="read-only" value={4} size="small" readOnly />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs='auto'>
                                                        <Divider orientation="vertical" sx={{ borderColor: '#fff', }} />
                                                    </Grid>

                                                    <Grid item xs>
                                                        <Typography variant="h5" color="text.white">
                                                            Core Activities
                                                        </Typography>
                                                        <Stack direction="row" spacing={1}>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Walking"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Reading"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Meditation"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>
                                                            </Box>
                                                            <Box>
                                                                <List sx={{ p: 0 }}>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Swimming"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Games"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                    <ListItem disableGutters sx={{ py: 0 }}>
                                                                        <ListItemIcon sx={{ minWidth: '20px' }}>
                                                                            <img alt="" src={`/images/icons/tick.png`} />
                                                                        </ListItemIcon>
                                                                        <ListItemText
                                                                            primary="Dancing"
                                                                            primaryTypographyProps={{
                                                                                fontSize: '1rem',
                                                                                color: 'text.white'
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                </List>

                                                            </Box>
                                                        </Stack>
                                                        <Divider sx={{ borderColor: 'text.white', my: 1, width: '75%', mx: 'auto' }} />

                                                        <Box sx={{ textAlign: 'center' }}>
                                                            <Typography variant="body2" color="text.white">
                                                                Google
                                                            </Typography>
                                                            <StyledRating name="read-only" value={3} size="small" readOnly />
                                                        </Box>

                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Chip label="See More" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Compare" color="orange" size="small" />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Chip label="Quick Connect" color="orange" size="small" />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid>

                                        </Grid>

                                    </CardActions>
                                </Card>

                            </Grid>

                        </Grid>

                        <Button
                            variant="contained"
                            size="large"
                        >
                            See More Results
                        </Button>

                        <Grid container spacing={5} sx={{ my: 3 }}>
                            <Grid item xs={6}>
                                <Box
                                    component="img"
                                    width='100%'
                                    src="/images/business_medium_ad.png"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Box
                                    width='100%'
                                    component="img"
                                    src="/images/business_medium_ad.png"
                                />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

            </Box>

            <Footer />

        </Box>
    )
}

export default RecreationalSearchResult;