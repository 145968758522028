import {
    Container, Box, Grid,
    Typography, Divider, Stack
} from '@mui/material';

import ContactUs from '../Home/ContactUs.js';
import Search from '../Search';

import Navbar from '../Navbar/Navbar';
import Subscribe1 from '../Subscribe/index.js';
import Footer from '../Footer/Footer.js';


const AboutCompany = () => {

    return (
        <>
            <Box sx={{ background: '#FFC514' }}>
                <Container maxWidth="lg"
                    sx={{
                        pb: 5,
                        backgroundImage: 'url(./images/child-bg.png)',
                        backgroundPosition: 'bottom right',
                        backgroundRepeat: 'no-repeat'
                    }}
                >
                    <Navbar />
                    <Search />

                </Container>
            </Box>

            <Box sx={{
                height: '134px',
                backgroundImage: 'url(./images/ads-banner-bg.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}
            >
                <Box
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                        width: '100%', height: '100%'
                    }}
                >
                    {<img src='/images/ad_banner_h.png' alt="Ads" />}
                </Box>
            </Box>



            <Container maxWidth="lg" >

                <Box>
                    <Stack direction='row' spacing={5}>
                        <Typography variant="h1" color="text.primary" sx={{ pt: 3 }}>
                            About Us
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {<img alt="" src="/images/icons/butterfly.png" />}
                        </Box>

                    </Stack>
                    <Divider sx={{ maxWidth: '150px', borderColor: 'text.secondary', mb: 3 }} flexItem />

                    <Grid container spacing={5}>
                        <Grid item xs>
                            <Box>
                                <Typography variant='subtitle2' color="secondary.main" >
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat id velit id rutrum. Etiam lacinia ligula vitae odio convallis ornare. In viverra dignissim arcu, auctor gravida velit ultricies non. Nam venenatis nunc eu sem porta, quis porttitor metus ornare. Aenean et vulputate diam, sit amet malesuada magna. Aliquam quis pellentesque massa, in pretium felis. Donec vestibulum nulla et sapien convallis molestie. Ut ornare, sapien non fringilla efficitur, sapien tortor sollicitudin mi, at lobortis ex leo quis mi. Fusce faucibus neque finibus ipsum pellentesque ultricies. In feugiat viverra fermentum. Suspendisse fermentum elit vel odio viverra laoreet. Vestibulum finibus tortor ligula, sit amet scelerisque turpis tincidunt in. Proin ac venenatis ex.
                                </Typography>

                                <Typography variant='subtitle2' color="secondary.main" sx={{ mt: 3 }}>
                                    Sed diam augue, tincidunt vel eros vitae, lobortis viverra lectus. Duis leo sem, consectetur sit amet tellus a, fringilla posuere augue. Aenean mollis dapibus suscipit. Proin ornare ante sed fringilla lacinia. Proin maximus dapibus orci a consectetur. Ut pretium neque a velit ornare porta. In vel sem ac est convallis mollis. Duis varius lacus eu sagittis pellentesque. Duis maximus ex non arcu scelerisque.
                                </Typography>
                            </Box>

                            <Box sx={{ mt: 5 }}>
                                <Typography variant="h1" color="text.primary">
                                    Our Vision
                                </Typography>
                                <Divider sx={{ maxWidth: '150px', borderColor: 'text.secondary' }} flexItem />

                                <Typography variant='subtitle2' color="secondary.main" sx={{ mt: 3 }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut feugiat id velit id rutrum. Etiam lacinia ligula vitae odio convallis ornare. In viverra dignissim arcu, auctor gravida velit ultricies non. Nam venenatis nunc eu sem porta, quis porttitor metus ornare. Aenean et vulputate diam, sit amet malesuada magna. Aliquam quis pellentesque massa, in pretium felis. Donec vestibulum nulla et sapien convallis molestie. Ut ornare, sapien non fringilla efficitur, sapien tortor sollicitudin mi, at lobortis ex leo quis mi. Fusce faucibus neque finibus ipsum pellentesque ultricies. In feugiat viverra fermentum. Suspendisse fermentum elit vel odio viverra laoreet. Vestibulum finibus tortor ligula, sit amet scelerisque turpis tincidunt in. Proin ac venenatis ex.
                                </Typography>

                                <Typography variant='subtitle2' color="secondary.main" sx={{ mt: 3 }}>
                                    Sed diam augue, tincidunt vel eros vitae, lobortis viverra lectus. Duis leo sem, consectetur sit amet tellus a, fringilla posuere augue. Aenean mollis dapibus suscipit. Proin ornare ante sed fringilla lacinia. Proin maximus dapibus orci a consectetur. Ut pretium neque a velit ornare porta. In vel sem ac est convallis mollis. Duis varius lacus eu sagittis pellentesque. Duis maximus ex non arcu scelerisque.
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs='auto' sx={{ textAlign: 'right' }}>
                            <Box sx={{ mb: 2 }}>
                                {<img alt="" src="/images/ad.png" />}
                            </Box>
                            <Box>
                                {<img alt="" src="/images/ad.png" />}
                            </Box>

                        </Grid>

                    </Grid>

                </Box>

            </Container>

            <Subscribe1 />


            <Container maxWidth="lg" >

                <Box sx={{ my: 3 }}>
                    <Typography variant="h1">
                        Our Team
                    </Typography>
                    <Divider sx={{ maxWidth: '150px', borderColor: 'text.secondary' }} flexItem />
                    <Grid container spacing={5} sx={{ my: 5 }}>
                        <Grid item xs={4} sx={{ textAlign: 'center', mt: 5 }}>
                            {<img src="/images/team-1.png" alt="team" />}
                            <Typography variant="h4" color="blued.main">Sarah</Typography>
                            <Typography variant="h5" color="text.gray">House Wife</Typography>
                            <Typography variant="subtitle1" color="text.dark">
                                Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet,
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'center' }}>
                            {<img src="/images/team-2.png" alt="team" />}
                            <Typography variant="h3" color="blued.main">Aman Khan</Typography>
                            <Typography variant="h4" color="text.gray">Teacher</Typography>
                            <Typography variant="body2" color="text.dark">
                                Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet,
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'center', mt: 5 }}>
                            {<img src="/images/team-3.png" alt="team" />}
                            <Typography variant="h4" color="blued.main">Sarah</Typography>
                            <Typography variant="h5" color="text.gray">House Wife</Typography>
                            <Typography variant="subtitle1" color="text.dark">
                                Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet, rutrum aliquam felis. Integer euismod aliquet mauris sed volutpat.Nam imperdiet venenatis magna, in malesuada lectus tincidunt at. Nullam lorem est, lacinia id tortor sit amet,
                            </Typography>
                        </Grid>
                    </Grid>

                </Box>



            </Container>

            <ContactUs />

            <Footer />

        </>
    );
}

export default AboutCompany;