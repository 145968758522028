import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Link, Modal, Radio, RadioGroup, Stack, TextField, Typography, textFieldClasses } from "@mui/material"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #091e44',
    boxShadow: 24,
    textAlign: 'center',
    p: 10
};


const SignupModal = (props) => {


    return (
        <Modal
            open={props.open}
            onClose={props.handleSignupClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

                <Typography variant="h2">
                    Welcome to my Daycare
                </Typography>

                <Typography variant="body2" color='text.dark'>
                    Signup with
                </Typography>

                <Stack direction='row' spacing={3} justifyContent='center'>
                    {<img src="/images/icons/fb_blue.png" />}
                    {<img src="/images/icons/apple_blue.png" />}
                    {<img src="/images/icons/email_blue.png" />}
                </Stack>

                <Typography variant="h5" color='text.dark' sx={{ textAlign: 'left' }}>
                    Email
                </Typography>
                <TextField
                    name="email"
                    label="Enter your email"
                    autoComplete="email"
                    size="small"
                    fullWidth
                // value={formik.values.firstname}
                // onChange={formik.handleChange}
                // error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                // helperText={formik.touched.firstname && formik.errors.firstname}
                />

                <Typography variant="h5" color='text.dark' sx={{ textAlign: 'left', mt: 2 }}>
                    Password
                </Typography>

                <TextField
                    name="email"
                    label="Enter your password"
                    autoComplete="email"
                    size="small"
                    fullWidth
                // value={formik.values.firstname}
                // onChange={formik.handleChange}
                // error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                // helperText={formik.touched.firstname && formik.errors.firstname}
                />

                <Typography variant="h5" color='text.dark' sx={{ textAlign: 'left', mt: 2 }}>
                    I am a
                </Typography>

                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        <Grid container>
                            <Grid item xs={6} sx={{ textAlign: 'left' }}>
                                <FormControlLabel value="parent" control={<Radio />} label="Parent" />
                                <FormControlLabel value="daycare" control={<Radio />} label="Business Owner Daycare" />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'left' }}>
                                <FormControlLabel value="otprofher" control={<Radio />} label="Industry Professional" />
                                <FormControlLabel value="rec" control={<Radio />} label="Business Owner Recreational" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </FormControl>



                <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    sx={{ mt: 3 }}
                >
                    Sign Up
                </Button>



            </Box>
        </Modal>
    )
}

export default SignupModal